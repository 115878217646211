import { CircularProgressClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';

export const CustomCircularProgress:
	| Partial<OverridesStyleRules<CircularProgressClassKey>>
	| undefined = {
	root: {
		'&.spinner': {
			height: '40px !important',
			width: '40px !important',
			color: getColor('spinner'),

			'@media screen and (min-width: 745px)': {
				height: '54px !important',
				width: '54px !important',
			},
		},
	},
};
