import React, { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { dialogService, DIALOGS, DialogMessage } from '../../../services/dialog.service';
import LogoIcon from '../../../../assets/images/logo-star.svg';
import styled from '@emotion/styled';
import { Spinner } from './spinner/Spinner';

const Loader = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	useEffect(() => {
		const sub = dialogService
			.getDialogSubjectAsObservable(DIALOGS.LOADER)
			.subscribe((message: DialogMessage) => {
				const { open } = message;
				setIsOpen(open);
			});

		return () => {
			sub.unsubscribe();
		};
	});

	const handleClose = (event: any, reason?: string) => {
		// reason: Can be: "escapeKeyDown", "backdropClick"
		if (reason) {
			return;
		}

		setIsOpen(false);
	};

	return (
		<Dialog open={isOpen} className="loaderDialog" onClose={handleClose}>
			<SpacerHelper />
			<StyledImage src={LogoIcon} alt="Mercedes-Benz Logo Icon" />
			<Spinner />
		</Dialog>
	);
};

export const LoaderMemo = React.memo(Loader);

const SpacerHelper = styled.div`
	height: 198px;

	@media only screen and (min-width: 745px) {
		height: 264px;
	}
`;

const StyledImage = styled.img`
	height: 150px;
	user-select: none;

	@media only screen and (min-width: 745px) {
		height: 220px;
	}
`;
