import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { dialogService, DIALOGS, DialogMessage } from '../../../services/dialog.service';

const PaymentLinkExpirationDialog = () => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [t] = useTranslation();

	const handleConfirm = () => {
		dialogService.closeDialog(DIALOGS.PAYMENT_EXPIRATION);
	};

	useEffect(() => {
		const sub = dialogService
			.getDialogSubjectAsObservable(DIALOGS.PAYMENT_EXPIRATION)
			.subscribe((message: DialogMessage) => {
				const { open } = message;
				setIsOpen(open);
			});

		return () => {
			sub.unsubscribe();
		};
	}, []);

	return (
		<Dialog open={isOpen} className="confirmDialog paymentLinkExpirationDialog">
			<DialogTitle>{t('paymentLinkExpirationDialog.title')}</DialogTitle>

			<DialogContent>{t('paymentLinkExpirationDialog.message')}</DialogContent>

			<DialogActions>
				<Button
					className="primary-color-button"
					type="button"
					variant="contained"
					onClick={handleConfirm}
					id="confirm-dialog-continue-button"
				>
					{t('paymentLinkExpirationDialog.ok')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export const PaymentLinkExpirationDialogMemo = React.memo(PaymentLinkExpirationDialog);
