import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { applicationService } from '../api/services/application/application.service';
import { removeDuplicatesByProperties } from '../util/general.util';
import {
	ASSET_TYPE,
	ASSET_DTO_TYPE_TO_ASSET_TYPE,
	ASSET_TYPE_TO_ASSET_DTO_TYPE,
	getAllowedAssetTypes,
	isAssetAuthStatusIncorrect,
} from '../util/assets.util';
import {
	RequestToPay,
	Card,
	Adyen,
	PaymentSlip,
	PROCESSING_OPTIONS,
	PaymentSettings,
	AssetDto,
	SepaAsset,
	CreditCardAsset,
	PayPalAsset,
	AssetsResponseDto,
	PaymentLinkInfoResponse,
	Assets,
	CreateInstantPaymentResponse,
	CardSessionInfoResponse,
	CreatePaymentSessionResponse,
	CreateGppTransactionResponse,
	CreateGppTransactionPayload,
	CreateSEPAMandateResponse,
	CreateSEPAMandatePayload,
	CreateNetbankingTransactionPayload,
	CreatePayPalTransactionResponseDto,
	CreateSepaTransactionDto,
	Creditor,
	DeleteAssetResponseDto,
	ExecutePayPalTransactionResponse,
	GetPaymentSlipResponseDto,
	CreateSEPAMandateResponseDto,
	CreateAccontToPayTransactionResponseDto,
	CreateAccontToPayTransactionPayload,
	CreateGppIndiaTransactionResponseDto,
	CreateUpiTransactionPayload,
	CreatePayPalTransactionPayload,
	AssetType,
	AssetNotificationRequestDto,
	PromptPayQR,
	CreatePromptPayTransactionResponse,
} from './types';

const toRequestToPay = (requestToPay: RequestToPay): RequestToPay => {
	return {
		processingOptions: requestToPay.processingOptions,
	};
};

const toCard = (card: Card): Card => {
	return {
		shopperBankStatement: card.shopperBankStatement,
		transactionType: card.transactionType,
	};
};

const toAdyen = (adyen: Adyen): Adyen => {
	return {
		sessionCreatorGpp: adyen.sessionCreatorGpp,
	};
};

const toPaymentSlip = (paymentSlip: PaymentSlip): PaymentSlip => {
	return {
		...paymentSlip,
		processingOption: paymentSlip.processingOption.toLowerCase() as PROCESSING_OPTIONS,
	};
};

const toPromptPayQR = (promptpayQr: PromptPayQR): PromptPayQR => {
	return {
		processingOptions: promptpayQr.processingOptions,
	};
};

const toPaymentSettings = (paymentSettings: PaymentSettings): PaymentSettings => {
	return {
		requestToPay: paymentSettings.requestToPay
			? toRequestToPay(paymentSettings.requestToPay)
			: undefined,
		card: paymentSettings.card ? toCard(paymentSettings.card) : undefined,
		adyen: paymentSettings.adyen ? toAdyen(paymentSettings.adyen) : undefined,
		sepa: paymentSettings.sepa,
		payPal: paymentSettings.payPal,
		paymentSlip: paymentSettings.paymentSlip
			? toPaymentSlip(paymentSettings.paymentSlip)
			: undefined,
		accountToPay: paymentSettings.accountToPay,
		promptpayQr: paymentSettings.promptpayQr
			? toPromptPayQR(paymentSettings.promptpayQr)
			: undefined,
	};
};

const ASSET_MAPPING_FUNCTIONS = {
	[ASSET_DTO_TYPE_TO_ASSET_TYPE.SEPA]: (assetDto: AssetDto): SepaAsset => {
		return {
			id: assetDto.id,
			type: ASSET_DTO_TYPE_TO_ASSET_TYPE[assetDto.type],
			accountHolder: assetDto.sepaData!.accountHolder,
			iban: assetDto.sepaData!.iban,
			isSelected: false,
			sepaMandateReference: assetDto.sepaData!.sepaMandateReference,
		};
	},
	[ASSET_DTO_TYPE_TO_ASSET_TYPE.CREDITCARD]: (assetDto: AssetDto): CreditCardAsset => {
		return {
			id: assetDto.id,
			type: ASSET_DTO_TYPE_TO_ASSET_TYPE[assetDto.type],
			holder: assetDto.data!.holder,
			lastDigits: assetDto.data!.lastDigits,
			brand: assetDto.data!.brand,
			isSelected: false,
			authenticationStatus: assetDto.data!.authenticationStatus,
		};
	},
	[ASSET_DTO_TYPE_TO_ASSET_TYPE.PAYPAL]: (assetDto: AssetDto): PayPalAsset => {
		return {
			id: assetDto.id,
			type: ASSET_DTO_TYPE_TO_ASSET_TYPE[assetDto.type],
			billingAgreementId: assetDto.paypalBillingData!.billingAgreementId,
			isSelected: false,
			customerAccountId: assetDto.paypalBillingData!.customerAccountId,
			payerEmail: assetDto.paypalBillingData!.payerEmail,
		};
	},
};

const toAssets = (
	response: AssetsResponseDto,
	paymentLinkInfo: PaymentLinkInfoResponse
): Assets => {
	const assets: {
		[ASSET_TYPE.CARD]: any[];
		[ASSET_TYPE.SEPA_DD]: any[];
		[ASSET_TYPE.PAYPAL]: any[];
	} = {
		[ASSET_TYPE.CARD]: [],
		[ASSET_TYPE.SEPA_DD]: [],
		[ASSET_TYPE.PAYPAL]: [],
	};

	// Adding the default asset
	if (response.defaultAsset) {
		if (!isAssetAuthStatusIncorrect(response.defaultAsset)) {
			const type = response.defaultAsset.type;
			const mappedDefaultAsset = ASSET_MAPPING_FUNCTIONS[ASSET_DTO_TYPE_TO_ASSET_TYPE[type]](
				response.defaultAsset
			);
			mappedDefaultAsset.isSelected = true;
			assets[ASSET_DTO_TYPE_TO_ASSET_TYPE[type]].push(mappedDefaultAsset);
		}
	}

	// Adding all other assets
	if (response.data) {
		getAllowedAssetTypes(paymentLinkInfo).forEach(
			(type) =>
				(assets[type] = [
					...assets[type],
					...response.data
						.filter((asset) => {
							const assetOfType = asset.type === ASSET_TYPE_TO_ASSET_DTO_TYPE[type];
							const assetAuthStatusIncorrect = isAssetAuthStatusIncorrect(asset);

							return assetOfType && !assetAuthStatusIncorrect;
						})
						.map((asset) => {
							return ASSET_MAPPING_FUNCTIONS[type](asset);
						}),
				])
		);
	}

	// Removing duplicate assets
	assets.SEPA_DD = removeDuplicatesByProperties([...assets.SEPA_DD], ['iban', 'accountHolder']);
	assets.CARD = removeDuplicatesByProperties([...assets.CARD], ['lastDigits', 'brand', 'holder']);

	// Handle the case where we do not have default asset
	// We need to still select the first asset
	if (!response.defaultAsset || isAssetAuthStatusIncorrect(response.defaultAsset)) {
		for (const assetType in assets) {
			if (assets[assetType as ASSET_TYPE].length > 0) {
				assets[assetType as ASSET_TYPE][0].isSelected = true;
				break;
			}
		}
	}

	return assets as Assets;
};

const toPaymentLinkInfoResponse = (response: PaymentLinkInfoResponse): PaymentLinkInfoResponse => {
	return {
		merchantId: response.merchantId,
		uuid: response.uuid,
		amount: response.amount,
		currency: response.currency,
		locale: response.locale,
		returnUrl: response.returnUrl,
		termsAndConditionsUrl: response.termsAndConditionsUrl,
		paymentMethods: response.paymentMethods,
		merchantName: response.merchantName,
		merchantReference: response.merchantReference,
		skipLandingPage: response.skipLandingPage,
		description: response.description,
		status: response.status,
		dateCreated: response.dateCreated,
		paymentSettings: response.paymentSettings
			? toPaymentSettings(response.paymentSettings)
			: undefined,
		returnUrlTimer: response.returnUrlTimer ? response.returnUrlTimer : 0,
		expirationDate: response.expirationDate,
		consumerReference: response.consumerReference,
		isSubscription: response.isSubscription,
		backToShopUrl: response.backToShopUrl,
		showAssetsIfSkipLandingPageIsTrue: response.showAssetsIfSkipLandingPageIsTrue,
		paypalMerchantId: response.paypalMerchantId,
		requireConsent: response.requireConsent,
	};
};

const getPaymentLinkInfo = (uuid: string): Observable<PaymentLinkInfoResponse> => {
	return applicationService.getPaymentLinkInfo(uuid).pipe(map(toPaymentLinkInfoResponse));
};

const toCreateInstantPaymentResponse = (
	response: CreateInstantPaymentResponse
): CreateInstantPaymentResponse => {
	return {
		redirectUrl: response.redirectUrl,
		paymentUuid: response.paymentUuid,
	};
};

const createInstantPayment = (uuid: string): Observable<CreateInstantPaymentResponse> => {
	return applicationService.createInstantPayment(uuid).pipe(map(toCreateInstantPaymentResponse));
};

const toInitiateCardPaymentSessionResponse = (
	response: CardSessionInfoResponse
): CardSessionInfoResponse => {
	return {
		token: response.token,
		integrationId: response.integrationId,
	};
};

const initiateCardPaymentSession = (uuid: string): Observable<CardSessionInfoResponse> => {
	return applicationService
		.initiateCardPaymentSession(uuid)
		.pipe(map(toInitiateCardPaymentSessionResponse));
};

const toCreatePaymentSessionResponse = (
	response: CreatePaymentSessionResponse
): CreatePaymentSessionResponse => {
	return {
		id: response.id,
		sessionData: response.sessionData,
		clientKey: response.clientKey,
	};
};

// Creates payment session for Adyen payment methods
const createPaymentSession = (
	uuid: string,
	paymentMethod: string
): Observable<CreatePaymentSessionResponse> => {
	return applicationService
		.createPaymentSession(uuid, paymentMethod)
		.pipe(map(toCreatePaymentSessionResponse));
};

const deleteAsset = (
	assetId: string,
	consumerReference: string,
	assetType: string
): Observable<DeleteAssetResponseDto> => {
	return applicationService.deleteAsset(assetId, consumerReference, assetType);
};

const getAssets = (paymentLinkInfo: PaymentLinkInfoResponse): Observable<Assets> => {
	return applicationService
		.getAssets(paymentLinkInfo.uuid)
		.pipe(map((response) => toAssets(response, paymentLinkInfo)));
};

const toSepaAssetSelectedData = (asset: AssetType) => {
	return {
		sepaData: {
			iban: (asset as SepaAsset).iban,
			accountHolder: (asset as SepaAsset).accountHolder,
			sepaMandateReference: (asset as SepaAsset).sepaMandateReference,
		},
	};
};

const toPaypalAssetSelectedData = (asset: AssetType) => {
	return {
		paypalBillingData: {
			billingAgreementId: (asset as PayPalAsset).billingAgreementId,
			customerAccountId: (asset as PayPalAsset).customerAccountId,
			payerEmail: (asset as PayPalAsset).payerEmail,
		},
	};
};

const toAssetSelectedData = (asset: AssetType) => {
	switch (asset.type) {
		case ASSET_TYPE.SEPA_DD:
			return toSepaAssetSelectedData(asset);
		case ASSET_TYPE.PAYPAL:
			return toPaypalAssetSelectedData(asset);
		default:
			return {};
	}
};

const toAssetSelectedRequest = (
	asset: AssetType,
	paymentLinkUuid: string
): AssetNotificationRequestDto => {
	return {
		assetType: ASSET_TYPE_TO_ASSET_DTO_TYPE[asset.type],
		paymentLinkId: paymentLinkUuid,
		id: asset.id,
		...toAssetSelectedData(asset),
	};
};

const callAssetSelected = (asset: AssetType, paymentLinkUuid: string) => {
	return applicationService.callAssetSelected(toAssetSelectedRequest(asset, paymentLinkUuid));
};

const toCreateTransactionResponse = (
	response: CreateGppTransactionResponse
): CreateGppTransactionResponse => {
	return {
		status: response.status,
		asynchronous: {
			threeDSecure2Fingerprint: response.asynchronous.threeDSecure2Fingerprint,
			threeDSecure2Challenge: response.asynchronous.threeDSecure2Challenge,
		},
	};
};

const createCardTransaction = (
	merchantId: string,
	payload: CreateGppTransactionPayload
): Observable<CreateGppTransactionResponse> => {
	return applicationService
		.createCardTransaction(merchantId, payload)
		.pipe(map(toCreateTransactionResponse));
};

const getCreditor = (merchantId: string): Observable<Creditor> => {
	return applicationService.getCreditor(merchantId);
};

const toCreateSEPAMandate = (response: CreateSEPAMandateResponseDto): CreateSEPAMandateResponse => {
	return {
		mandateId: response.uuid,
	};
};

const createSEPAMandate = (
	uuid: string,
	payload: CreateSEPAMandatePayload
): Observable<CreateSEPAMandateResponse> => {
	return applicationService.createSEPAMandate(uuid, payload).pipe(map(toCreateSEPAMandate));
};

const createSepaTransaction = (
	uuid: string,
	mandateId: string
): Observable<CreateSepaTransactionDto> => {
	return applicationService.createSepaTransaction(uuid, mandateId);
};

const createPayPalTransaction = (
	uuid: string,
	payload: CreatePayPalTransactionPayload
): Observable<CreatePayPalTransactionResponseDto> => {
	return applicationService.createPayPalTransaction(uuid, payload);
};

const executePayPalTransaction = (uuid: string): Observable<ExecutePayPalTransactionResponse> => {
	return applicationService.executePayPalTransaction(uuid);
};

const getPaymentSlip = (uuid: string): Observable<GetPaymentSlipResponseDto> => {
	return applicationService.getPaymentSlip(uuid);
};

const createNetbankingTransaction = (
	uuid: string,
	payload: CreateNetbankingTransactionPayload
): Observable<CreateGppIndiaTransactionResponseDto> => {
	return applicationService.createNetbankingTransaction(uuid, payload);
};

const createUPITransaction = (
	uuid: string,
	payload: CreateUpiTransactionPayload
): Observable<CreateGppIndiaTransactionResponseDto> => {
	return applicationService.createUPITransaction(uuid, payload);
};

const createAccountToPayTransaction = (
	uuid: string,
	payload: CreateAccontToPayTransactionPayload
): Observable<CreateAccontToPayTransactionResponseDto> => {
	return applicationService.createAccountToPayTransaction(uuid, payload);
};

const toCreatePromptPayResponse = (
	response: CreatePromptPayTransactionResponse
): CreatePromptPayTransactionResponse => {
	return {
		redirectUrl: response.redirectUrl,
		paymentUuid: response.paymentUuid,
	};
};

const createPromptPayTransaction = (
	uuid: string
): Observable<CreatePromptPayTransactionResponse> => {
	return applicationService.createPromptPayTransaction(uuid).pipe(map(toCreatePromptPayResponse));
};

export const paymentRepository = {
	getPaymentLinkInfo,
	createInstantPayment,
	initiateCardPaymentSession,
	createPaymentSession,
	deleteAsset,
	callAssetSelected,
	getAssets,
	createCardTransaction,
	getCreditor,
	createSEPAMandate,
	createSepaTransaction,
	createPayPalTransaction,
	executePayPalTransaction,
	getPaymentSlip,
	createNetbankingTransaction,
	createUPITransaction,
	createAccountToPayTransaction,
	createPromptPayTransaction,
};
