import { PAYMENT_METHODS } from '../../util/payment.util';

export enum GPP_TRANSACTION_STATUS {
	AUTHORIZED = 'AUTHORIZED',
	REFUSED = 'REFUSED',
	PENDING = 'PENDING',
	COMPLETED = 'COMPLETED',
}

export enum SEPA_TRANSACTION_STATUS {
	PENDING = 'PENDING',
}

export enum TRANSACTION_TYPE_STRING {
	preauth = 'preauth',
	preauthAndCapture = 'preauthAndCapture',
	debit = 'debit',
}

export enum PAYPAL_TRANSACTION_TYPE {
	preauth = TRANSACTION_TYPE_STRING.preauth,
	preauthAndCapture = TRANSACTION_TYPE_STRING.preauthAndCapture,
}

export interface CreateGppTransactionResponse {
	status: GPP_TRANSACTION_STATUS;
	asynchronous: {
		threeDSecure2Fingerprint: string;
		threeDSecure2Challenge: string;
	};
}

export type DeviceInfo = {
	colorDepth: number;
	screenHeight: number;
	screenWidth: number;
	timeZoneOffset: number;
	javaEnabled: boolean;
	deviceType: string;
	acceptHeader: string;
	language: string;
};

export type GPPTransactionPayment = {
	asset: string;
};

export type CreateGppTransactionPayload = {
	payment: GPPTransactionPayment;
	deviceInformation: DeviceInfo;
};

export type CreatePayPalTransactionPayload = {
	asset: string | null;
	correlationId: string | null;
};

export type AsynchronousData = {
	paymentId: string;
	redirectUrl: string;
	threeDSecure2Fingerprint: string;
	threeDSecure2Challenge: string;
	qrCodeData: string;
};

export interface Card {
	shopperBankStatement: string; // maxLength: 50, minLength: 1; example: Payment description; Complete description that appears on customers’ statements
	transactionType: TRANSACTION_TYPE_STRING; // Enum: [ preauth, preauthAndCapture, debit ]
}

export interface CardSessionInfoResponse {
	token: string; // The token is valid for one successful asset capture
	integrationId: string; // example: e3b0617a-b2eb-40aa-b40d-1ec711abfd5a; Value representing specific integration
}

export type CreateNetbankingTransactionDto = {
	id: string;
	status: GPP_TRANSACTION_STATUS;
	type: TRANSACTION_TYPE_STRING;
	paymentMethod: PAYMENT_METHODS;
	asset: string;
	asynchronous: {
		paymentId: string;
		redirectUrl: string;
	};
};

export type CreateGppIndiaTransactionResponseDto = {
	id: string;
	status: GPP_TRANSACTION_STATUS;
	type: TRANSACTION_TYPE_STRING;
	paymentMethod: PAYMENT_METHODS;
	asset: string;
	asynchronous: {
		paymentId: string;
		redirectUrl: string;
	};
};

export type CreateGppIndiaTransactionPayload = {
	firstName: string;
	email: string;
	phone: string;
};

export type CreateUpiTransactionPayload = CreateGppIndiaTransactionPayload & {
	vpa: string;
	paymentMethod: string;
};

export type CreateNetbankingTransactionPayload = CreateGppIndiaTransactionPayload;

export interface CreatePayPalTransactionResponseDto {
	id: string;
	status: string;
	asynchronous: AsynchronousData;
	type: PAYPAL_TRANSACTION_TYPE;
}

export interface PayPal {
	createBillingAgreement: boolean;
	transactionType: PAYPAL_TRANSACTION_TYPE; // "preauthAndCapture" OR "preauth"
}

type AsynchronousDataPayPalTransaction = {
	paymentId: string;
	redirectUrl: string;
};

export interface ExecutePayPalTransactionResponse {
	id: string;
	status: GPP_TRANSACTION_STATUS;
	type: TRANSACTION_TYPE_STRING;
	paymentMethod: string;
	asset: string;
	asynchronous: AsynchronousDataPayPalTransaction;
}
