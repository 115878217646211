import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from '../scroll-to-top/ScrollToTop';
import { LoaderMemo } from '../loader/Loader';

const PageNotFound = lazy(() => import('../../404/PageNotFound'));
const CardPayment = lazy(() => import('../../card-payment/CardPayment'));
const CardPaymentCompleted = lazy(() => import('../../card-payment/CardPaymentCompleted'));
const DirectBankTransfer = lazy(() => import('../../direct-bank-transfer/DirectBankTransfer'));
const Adyen = lazy(() => import('../../adyen/Adyen'));
const AdyenResult = lazy(() => import('../../adyen-result/AdyenResult'));
const SepaPayment = lazy(() => import('../../sepadd/SepaPayment'));
const PayPalPayment = lazy(() => import('../../paypal-payment/PayPalPayment'));
const UuidNotFound = lazy(() => import('../../uuid-not-found/UuidNotFound'));
const RedirectToPaymentResult = lazy(
	() => import('../../direct-bank-transfer/redirect-to-payment-result/RedirectToPaymentResult')
);
const LandingPage = lazy(() => import('../../landing-page/LandingPage'));
const PaymentSuccess = lazy(() => import('../../payment-success/PaymentSuccess'));
const PaymentFailure = lazy(() => import('../../payment-failure/PaymentFailure'));
const PayPalTransactionCreated = lazy(
	() => import('../../paypal-transaction-created/PayPalTransactionCreated')
);
const PaymentSlipPayment = lazy(() => import('../../payment-slip-payment/PaymentSlip'));
const NetbankingPayment = lazy(() => import('../../netbanking/NetbankingPayment'));
const UPIPayment = lazy(() => import('../../upi-payment-method/UPIPayment'));
const IndianMethodTransactionCreated = lazy(
	() => import('../../indian-method-transaction-created/IndianMethodTransactionCreated')
);
const CardChallenge = lazy(() => import('../../card-payment/CardChallenge'));
const AccountToPayPayment = lazy(() => import('../../account-to-pay-payment/AccountToPayPayment'));
const PromptPayPayment = lazy(() => import('../../prompt-pay-payment/PromptPayPayment'));

const HppRoutes = () => {
	return (
		<>
			<ScrollToTop />
			<Suspense fallback={<LoaderMemo />}>
				<Routes>
					<Route path="/">
						<Route path="*" element={<UuidNotFound />} />
						<Route index element={<UuidNotFound />} />
						<Route path="uuid">
							<Route index element={<UuidNotFound />} />
							<Route path=":uuid">
								<Route index element={<LandingPage />} />
								<Route path="card" element={<CardPayment />} />
								<Route path="card-challenge" element={<CardChallenge />} />
								<Route path="card-payment-completed" element={<CardPaymentCompleted />} />
								<Route path="direct-bank-transfer" element={<DirectBankTransfer />} />
								<Route path="netbanking" element={<NetbankingPayment />} />
								<Route path="upi" element={<UPIPayment />} />
								<Route path="upiqrcode" element={<UPIPayment />} />
								<Route
									path="netbanking-transaction-created"
									element={<IndianMethodTransactionCreated />}
								/>
								<Route
									path="upi-transaction-created"
									element={<IndianMethodTransactionCreated />}
								/>
								<Route path="sepa-dd" element={<SepaPayment />} />
								<Route path="paypal" element={<PayPalPayment />} />
								<Route path="paypal-transaction-created" element={<PayPalTransactionCreated />} />
								<Route path="payment-slip" element={<PaymentSlipPayment />} />
								<Route path="account-to-pay" element={<AccountToPayPayment />} />
								<Route path="promptpayqr" element={<PromptPayPayment />} />

								{/* Deutsche Bank and PromptPay redirect back to this */}
								<Route
									path="mid/:mid/success"
									element={<RedirectToPaymentResult success={true} />}
								/>
								<Route
									path="mid/:mid/failure"
									element={<RedirectToPaymentResult success={false} />}
								/>

								{/* Adyen payment methods */}
								<Route path="ideal" element={<Adyen />} />
								<Route path="bancontact-card" element={<Adyen />} />
								<Route path="bancontact-mobile" element={<Adyen />} />
								<Route path="kcp" element={<Adyen />} />

								{/* Adyen redirects back to this */}
								<Route path="ckey/:ckey/adyen" element={<AdyenResult />} />

								{/* We redirect here after Adyen and Card flow */}
								<Route path="success" element={<PaymentSuccess />} />
								<Route path="failure" element={<PaymentFailure />} />

								<Route path="*" element={<PageNotFound />} />
							</Route>
						</Route>
					</Route>
				</Routes>
			</Suspense>
		</>
	);
};

export const HppRoutesMemo = React.memo(HppRoutes);
