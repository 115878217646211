import { appSetup } from '../../../index';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { from, map } from 'rxjs';
import {
	PaymentLinkInfoResponse,
	CreateInstantPaymentResponse,
	CardSessionInfoResponse,
	CreatePaymentSessionResponse,
	DeleteAssetResponseDto,
	AssetsResponseDto,
	CreateGppTransactionPayload,
	CreateGppTransactionResponse,
	Creditor,
	CreateSEPAMandatePayload,
	CreateSEPAMandateResponseDto,
	CreateSepaTransactionDto,
	CreatePayPalTransactionResponseDto,
	ExecutePayPalTransactionResponse,
	GetPaymentSlipResponseDto,
	CreateNetbankingTransactionPayload,
	CreateAccontToPayTransactionResponseDto,
	CreateAccontToPayTransactionPayload,
	CreateGppIndiaTransactionResponseDto,
	CreateUpiTransactionPayload,
	CreatePayPalTransactionPayload,
	AssetNotificationRequestDto,
} from '../../../data/types';
import { CreatePromptPayTransactionResponse } from '../../../data/types/prompt-pay';

const PAYMENT_LINK_VISITED_BY_USER_HEADER = 'HPP-Payment-Link-Visited-By-User';

const getPaymentLinkInfo = (uuid: string) => {
	const url = `/public/payment-link/uuid/${uuid}`;

	const requestConfig: AxiosRequestConfig = {
		headers: {
			[PAYMENT_LINK_VISITED_BY_USER_HEADER]: 'true',
		},
	};

	return from(appSetup.getApplicationApi().get<PaymentLinkInfoResponse>(url, requestConfig)).pipe(
		map((response: AxiosResponse<PaymentLinkInfoResponse>) => response.data)
	);
};

const createInstantPayment = (uuid: string) => {
	const url = `/public/request-to-pay/payment-link/${uuid}`;

	return from(appSetup.getApplicationApi().post<CreateInstantPaymentResponse>(url, {})).pipe(
		map((response: AxiosResponse<CreateInstantPaymentResponse>) => response.data)
	);
};

const initiateCardPaymentSession = (uuid: string) => {
	const url = `/public/card/payment-link/${uuid}`;

	return from(appSetup.getApplicationApi().post<CardSessionInfoResponse>(url, {})).pipe(
		map((response: AxiosResponse<CardSessionInfoResponse>) => response.data)
	);
};

// Creates payment session for Adyen payment methods
const createPaymentSession = (uuid: string, paymentMethod: string) => {
	const url = `/public/other-payments/adyen/sessions/${uuid}`;

	return from(
		appSetup.getApplicationApi().post<CreatePaymentSessionResponse>(url, { paymentMethod })
	).pipe(map((response: AxiosResponse<CreatePaymentSessionResponse>) => response.data));
};

// Assuming that on the BE - route for deleting assets will be:
const deleteAsset = (assetId: string, uuid: string, assetType: string) => {
	const url = `/public/payment-link/${uuid}/asset/${assetId}/type/${assetType}`;

	return from(appSetup.getApplicationApi().delete<DeleteAssetResponseDto>(url)).pipe(
		map((response: AxiosResponse<DeleteAssetResponseDto>) => response.data)
	);
};

const callAssetSelected = (payload: AssetNotificationRequestDto) => {
	const url = '/public/asset/event';

	return from(appSetup.getApplicationApi().post<any>(url, payload)).pipe(
		map((response: AxiosResponse<any>) => response.data)
	);
};

const getAssets = (uuid: string) => {
	const url = `/public/payment-link/${uuid}/assets`;

	return from(appSetup.getApplicationApi().get<AssetsResponseDto>(url)).pipe(
		map((response: AxiosResponse<AssetsResponseDto>) => response.data)
	);
};

const createCardTransaction = (uuid: string, payload: CreateGppTransactionPayload) => {
	const url = `/public/payment-link/${uuid}/transaction`;

	return from(appSetup.getApplicationApi().post<CreateGppTransactionResponse>(url, payload)).pipe(
		map((response: AxiosResponse<CreateGppTransactionResponse>) => response.data)
	);
};

const getCreditor = (merchantId: string) => {
	const url = `/public/merchant/${merchantId}/creditor`;

	return from(appSetup.getApplicationApi().get<Creditor>(url)).pipe(
		map((response: AxiosResponse<Creditor>) => response.data)
	);
};

const createSEPAMandate = (uuid: string, payload: CreateSEPAMandatePayload) => {
	const url = `/public/sepa/merchant/${uuid}/mandate`;

	return from(appSetup.getApplicationApi().post<CreateSEPAMandateResponseDto>(url, payload)).pipe(
		map((response: AxiosResponse<CreateSEPAMandateResponseDto>) => response.data)
	);
};

const createSepaTransaction = (uuid: string, mandateId: string) => {
	const payload = {
		asset: mandateId,
	};
	const url = `/public/sepa/merchant/${uuid}/transaction`;

	return from(appSetup.getApplicationApi().post<CreateSepaTransactionDto>(url, payload)).pipe(
		map((response: AxiosResponse<CreateSepaTransactionDto>) => response.data)
	);
};

const createPayPalTransaction = (uuid: string, payload: CreatePayPalTransactionPayload) => {
	const url = `/public/paypal/merchant/${uuid}/transaction`;

	return from(
		appSetup.getApplicationApi().post<CreatePayPalTransactionResponseDto>(url, payload)
	).pipe(map((response: AxiosResponse<CreatePayPalTransactionResponseDto>) => response.data));
};

const executePayPalTransaction = (uuid: string) => {
	const url = `/public/paypal/merchant/${uuid}/transaction/execute`;

	return from(appSetup.getApplicationApi().post<ExecutePayPalTransactionResponse>(url, {})).pipe(
		map((response: AxiosResponse<ExecutePayPalTransactionResponse>) => response.data)
	);
};

const getPaymentSlip = (uuid: string) => {
	const url = `/public/payment-slip/payment-link/${uuid}`;

	return from(appSetup.getApplicationApi().post<GetPaymentSlipResponseDto>(url)).pipe(
		map((response: AxiosResponse<GetPaymentSlipResponseDto>) => response.data)
	);
};

const createNetbankingTransaction = (uuid: string, payload: CreateNetbankingTransactionPayload) => {
	const url = `/public/netbanking/payment-link/${uuid}/transaction`;

	return from(
		appSetup.getApplicationApi().post<CreateGppIndiaTransactionResponseDto>(url, payload)
	).pipe(map((response: AxiosResponse<CreateGppIndiaTransactionResponseDto>) => response.data));
};

const createUPITransaction = (uuid: string, payload: CreateUpiTransactionPayload) => {
	const url = `/public/upi/payment-link/${uuid}/transaction`;

	return from(
		appSetup.getApplicationApi().post<CreateGppIndiaTransactionResponseDto>(url, payload)
	).pipe(map((response: AxiosResponse<CreateGppIndiaTransactionResponseDto>) => response.data));
};

const createAccountToPayTransaction = (
	uuid: string,
	payload: CreateAccontToPayTransactionPayload
) => {
	const url = `/public/account-to-pay/payment-link/${uuid}`;

	return from(
		appSetup.getApplicationApi().post<CreateAccontToPayTransactionResponseDto>(url, payload)
	).pipe(map((response: AxiosResponse<CreateAccontToPayTransactionResponseDto>) => response.data));
};

const createPromptPayTransaction = (uuid: string) => {
	const url = `/public/prompt-pay/payment-link/${uuid}`;

	return from(appSetup.getApplicationApi().post<CreatePromptPayTransactionResponse>(url, {})).pipe(
		map((response: AxiosResponse<CreatePromptPayTransactionResponse>) => response.data)
	);
};

export const applicationService = {
	getPaymentLinkInfo,
	createInstantPayment,
	initiateCardPaymentSession,
	createPayPalTransaction,
	createPaymentSession,
	deleteAsset,
	callAssetSelected,
	getAssets,
	createCardTransaction,
	getCreditor,
	createSEPAMandate,
	createSepaTransaction,
	executePayPalTransaction,
	getPaymentSlip,
	createNetbankingTransaction,
	createUPITransaction,
	createAccountToPayTransaction,
	createPromptPayTransaction,
};
