/* eslint-disable */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation_EN from './core/locales/en/translation';
import translation_KO from './core/locales/ko/translation';
import translation_FR from './core/locales/fr/translation';
import translation_NL from './core/locales/nl/translation';
import translation_DE from './core/locales/de/translation';
import translation_IT from './core/locales/it/translation';
import translation_ES from './core/locales/es/translation';
import translation_SV from './core/locales/sv/translation';
import translation_DA from './core/locales/da/translation';
import translation_PT from './core/locales/pt/translation';
import translation_TR from './core/locales/tr/translation';

const resources = {
	en: {
		translation: translation_EN,
	},
	ko: {
		translation: translation_KO,
	},
	fr: {
		translation: translation_FR,
	},
	nl: {
		translation: translation_NL,
	},
	de: {
		translation: translation_DE,
	},
	it: {
		translation: translation_IT,
	},
	es: {
		translation: translation_ES,
	},
	sv: {
		translation: translation_SV,
	},
	da: {
		translation: translation_DA,
	},
	pt: {
		translation: translation_PT,
	},
	tr: {
		translation: translation_TR,
	},
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		fallbackLng: 'en', // use en translations if detected language is not available
		keySeparator: true,
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
