import { CUSTOM_CSS_GPP_FORM } from './theme.util';

export const VIETNAM_INTEGRATION_ID_CUSTOM_CSS_GPP_FORM =
	CUSTOM_CSS_GPP_FORM +
	` 
                    .submit_button:disabled {
                        background-color: #0078d6 !important;
                        color: #ffffff !important;
                    }
    `;
