import { ICON_TYPE, PAYMENT_LINK_STATUS } from './payment.util';

export enum API_ERROR_CODES {
	PAYMENT_LINK_INVALIDATED = 'PAYMENT_LINK_INVALIDATED',
	PAYMENT_LINK_CONSUMED = 'PAYMENT_LINK_CONSUMED',
	PAYMENT_LINK_EXPIRED = 'PAYMENT_LINK_EXPIRED',
	NOT_ALLOWED_PAYMENT_METHOD = 'NOT_ALLOWED_PAYMENT_METHOD',
	PAYMENT_ALREADY_EXISTS = 'PAYMENT_ALREADY_EXISTS',
	REQUEST_TO_PAY_MERCHANT_NOT_MAPPED = 'REQUEST_TO_PAY_MERCHANT_NOT_MAPPED',
	GPP_MERCHANT_NOT_MAPPED = 'GPP_MERCHANT_NOT_MAPPED',
}

export type HPPError = {
	errorTitle: string;
	errorDescription: string;
	errorIconType: ICON_TYPE;
	concreteError: Error | null;
};

export const defaultError: HPPError = {
	errorTitle: 'general.genericError',
	errorDescription: 'general.genericErrorDesc',
	errorIconType: ICON_TYPE.ERROR,
	concreteError: null,
};

export const paymentExpiredError: HPPError = {
	errorTitle: 'paymentLinkError.expired',
	errorDescription: 'paymentLinkError.expiredDesc',
	errorIconType: ICON_TYPE.INFO,
	concreteError: null,
};

type HPPErrorMap = { [name: string]: HPPError };

const PaymentAPIErrorByCode: HPPErrorMap = {
	[API_ERROR_CODES.PAYMENT_LINK_INVALIDATED]: {
		errorTitle: 'errorMessages.409Expired',
		errorDescription: 'errorMessages.409ExpiredDesc',
		errorIconType: ICON_TYPE.ERROR,
		concreteError: null,
	},
	[API_ERROR_CODES.PAYMENT_LINK_CONSUMED]: {
		errorTitle: 'errorMessages.409Consumed',
		errorDescription: '',
		errorIconType: ICON_TYPE.ERROR,
		concreteError: null,
	},
	[API_ERROR_CODES.PAYMENT_LINK_EXPIRED]: {
		errorTitle: 'errorMessages.409Expired',
		errorDescription: 'errorMessages.409ExpiredDesc',
		errorIconType: ICON_TYPE.ERROR,
		concreteError: null,
	},
};

/**
 * Parses the given error object from the Payment API and returns an HPPError object.
 * @param error - Error object received from the Payment API.
 * @returns An HPPError object representing the parsed error, or a default error object if parsing fails.
 */
export const getPaymentAPIOperationHPPError = (error: any): HPPError => {
	const status = error?.response.status;
	const errorCode = error?.response.data.errorCode;
	const shouldReturnDefaultError = !error || status !== 409 || !PaymentAPIErrorByCode[errorCode];

	if (shouldReturnDefaultError) {
		return { ...defaultError };
	}

	const hppError: HPPError = { ...PaymentAPIErrorByCode[errorCode] };
	hppError.concreteError = error;

	return hppError;
};

const PaymentResultErrorByStatus: HPPErrorMap = {
	[PAYMENT_LINK_STATUS.EXPIRED]: {
		errorTitle: 'paymentLinkError.expired',
		errorDescription: 'paymentLinkError.expiredDesc',
		errorIconType: ICON_TYPE.INFO,
		concreteError: null,
	},
	[PAYMENT_LINK_STATUS.CONSUMED]: {
		errorTitle: 'paymentLinkError.consumed',
		errorDescription: 'paymentLinkError.consumedDesc',
		errorIconType: ICON_TYPE.INFO,
		concreteError: null,
	},
	[PAYMENT_LINK_STATUS.INVALIDATED]: {
		errorTitle: 'paymentLinkError.invalidated',
		errorDescription: 'paymentLinkError.invalidatedDesc',
		errorIconType: ICON_TYPE.INFO,
		concreteError: null,
	},
	[PAYMENT_LINK_STATUS.PROCESSING_PAYMENT]: {
		errorTitle: 'paymentLinkError.processing',
		errorDescription: 'paymentLinkError.processingDesc',
		errorIconType: ICON_TYPE.INFO,
		concreteError: null,
	},
};

/**
 * Retrieves an HPPError object based on the provided payment link status.
 * @param status - Status of the payment link (e.g., EXPIRED, CONSUMED, INVALIDATED, PROCESSING_PAYMENT).
 * @returns An HPPError object corresponding to the provided payment link status.
 */
export const getPaymentResultHPPError = (status: PAYMENT_LINK_STATUS): HPPError => {
	return { ...PaymentResultErrorByStatus[status] };
};
