import { DialogClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';
import { getFont } from '../../fonts/fonts';

export const CustomDialog: Partial<OverridesStyleRules<DialogClassKey>> | undefined = {
	root: {
		'&.loaderDialog': {
			// must be higher than default
			zIndex: 1301,

			'& .MuiBackdrop-root': {
				backgroundColor: getColor('headerFooterSplash'),
			},

			'& .MuiDialog-container': {
				paddingBottom: '1vh',
			},

			'& .MuiDialog-paper': {
				backgroundColor: 'transparent',
				boxShadow: 'none',
			},
		},

		'&.confirmDialog': {
			'& .MuiBackdrop-root': {
				background: 'rgba(0, 0, 0, 0.8)',
			},

			'& .MuiPaper-root': {
				minHeight: '369px',
				minWidth: '284px',
				borderRadius: 0,
			},

			'& .MuiDialogTitle-root': {
				padding: '24px 16px 9px 16px',
				fontSize: '26px',
				lineHeight: '32px',
				fontWeight: 400,
				fontFamily: getFont('headingFont'),
				color: getColor('heading'),
			},

			'& .MuiDialogContent-root': {
				padding: '0px 16px 18px 16px',
				fontSize: '16px',
				lineHeight: '24px',
				fontWeight: 400,
				fontFamily: getFont('mainFont'),
				color: getColor('dialogContrast'),
			},

			'& .MuiDialogActions-root': {
				padding: '24px 16px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				alignItems: 'center',
				height: '176px',
				border: '1px solid #e8e8e8',
			},

			'& .MuiButton-root': {
				margin: 0,
				width: '100%',
			},
		},

		'&.paymentLinkExpirationDialog': {
			'& .MuiDialogActions-root': {
				display: 'flex',
				flexDirection: 'column-reverse',
				justifyContent: 'center',
				alignItems: 'center',
			},
		},

		'@media screen and (min-width: 745px)': {
			'&.confirmDialog': {
				'& .MuiPaper-root': {
					minHeight: '269px',
					minWidth: '575px',
					borderRadius: '4px',
				},

				'& .MuiDialogTitle-root': {
					padding: '22px 24px 9px 24px',
					fontSize: '34px',
					lineHeight: '44px',
				},

				'& .MuiDialogContent-root': {
					padding: '0px 24px 26px 24px',
					fontSize: '18px',
					lineHeight: '28px',
				},

				'& .MuiDialogActions-root': {
					padding: '32px 24px',
					display: 'flex',
					height: '120px',
					flexDirection: 'row',
				},

				'& .MuiButton-root': {
					width: 'auto',
				},
			},
		},
	},
};
