import AppContext, { AppContextModel, CallbackObj, PaymentInfoModel } from './app-context';
import { ReactNode, useCallback, useReducer, useRef } from 'react';
import { PAYMENT_LINK_STATUS, PaymentUtil, setSelectedPaymentMethod } from '../util/payment.util';
import { useNavigate } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { paymentRepository } from '../data/payment-repository';
import { dialogService, DIALOGS } from '../core/services/dialog.service';
import { paymentLocalStorageService } from '../core/services/local-storage/payment-link-ls.service';
import { skipLandingPageIfPossible, getPathParam, PATH_PARAM_NAME } from '../util/general.util';
import { defaultError, HPPError } from '../util/error.utils';
import { ASSET_TYPE, ASSET_TYPE_TO_ASSET_DTO_TYPE } from '../util/assets.util';
import useDeviceInfo from '../core/hooks/useDeviceInfo';
import {
	AssetType,
	Assets,
	CreateGppTransactionPayload,
	CreateGppTransactionResponse,
	CreatePayPalTransactionPayload,
	CreatePayPalTransactionResponseDto,
	GPP_TRANSACTION_STATUS,
	PaymentLinkInfoResponse,
	SEPA_TRANSACTION_STATUS,
} from '../data/types';

interface Props {
	children?: ReactNode;
}

enum ACTION_TYPE {
	SET_PAYMENT_INFO = 'SET_PAYMENT_INFO',
	SET_ERROR = 'SET_ERROR',
	CLEAR_ERROR = 'CLEAR_ERROR',
	SET_ASSETS = 'SET_ASSETS',
	SELECT_ASSET = 'SELECT_ASSET',
	SET_CORRELATION_ID = 'SET_CORRELATION_ID',
}

type State = {
	paymentInfo: PaymentInfoModel | null;
	error: HPPError;
	assets: Assets | null;
	correlationId: string | null;
};

type Action =
	| {
			type: ACTION_TYPE.SET_PAYMENT_INFO;
			payload: PaymentInfoModel;
	  }
	| {
			type: ACTION_TYPE.SET_ERROR;
			payload: any;
	  }
	| {
			type: ACTION_TYPE.CLEAR_ERROR;
			payload: null;
	  }
	| {
			type: ACTION_TYPE.SET_ASSETS;
			payload: Assets;
	  }
	| {
			type: ACTION_TYPE.SELECT_ASSET;
			payload: string;
	  }
	| {
			type: ACTION_TYPE.SET_CORRELATION_ID;
			payload: string;
	  };

const selectAssetInState = (state: State, assetId: string): State => {
	const assets = { ...state.assets } as Assets;
	for (const assetType in assets) {
		for (const asset of assets[assetType as ASSET_TYPE]) {
			if (asset.id === assetId) {
				asset.isSelected = true;
			} else {
				asset.isSelected = false;
			}
		}
	}

	return { ...state, assets };
};

const appReducer = (state: State, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case ACTION_TYPE.SET_PAYMENT_INFO:
			return {
				...state,
				paymentInfo: { ...payload },
			};
		case ACTION_TYPE.SET_ERROR:
			return {
				...state,
				error: { ...payload },
			};
		case ACTION_TYPE.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		case ACTION_TYPE.SET_ASSETS:
			return {
				...state,
				assets: { ...payload },
			};
		case ACTION_TYPE.SELECT_ASSET:
			return selectAssetInState(state, payload);
		case ACTION_TYPE.SET_CORRELATION_ID:
			return {
				...state,
				correlationId: payload,
			};
		default:
			return { ...state };
	}
};

const AppContextProvider = ({ children }: Props) => {
	const [appState, dispatchAppAction] = useReducer(appReducer, {
		paymentInfo:
			paymentLocalStorageService.getPayment(
				getPathParam(window.location.pathname, PATH_PARAM_NAME.UUID)
			) || null,
		error: null,
		assets: null,
		correlationId: null,
	});

	const [deviceInfo] = useDeviceInfo();

	const setPaymentInfo = useCallback((paymentInfo: PaymentInfoModel) => {
		dispatchAppAction({
			type: ACTION_TYPE.SET_PAYMENT_INFO,
			payload: paymentInfo,
		});
	}, []);

	const setError = useCallback((error: HPPError) => {
		dispatchAppAction({
			type: ACTION_TYPE.SET_ERROR,
			payload: error,
		});
	}, []);

	const clearError = useCallback(() => {
		dispatchAppAction({
			type: ACTION_TYPE.CLEAR_ERROR,
			payload: null,
		});
	}, []);

	const setAssets = (assets: Assets) => {
		dispatchAppAction({
			type: ACTION_TYPE.SET_ASSETS,
			payload: assets,
		});
	};

	const selectAsset = (assetId: string) => {
		dispatchAppAction({
			type: ACTION_TYPE.SELECT_ASSET,
			payload: assetId,
		});
	};

	const setCorrelationId = (correlationId: string) => {
		dispatchAppAction({
			type: ACTION_TYPE.SET_CORRELATION_ID,
			payload: correlationId,
		});
	};

	const navigate = useNavigate();

	const getPaymentLinkInfoRef = useRef(new Subscription());
	const createCardTransactionRef = useRef(new Subscription());
	const payWithSepaMandateRef = useRef(new Subscription());
	const payWithPPAssetRef = useRef(new Subscription());
	const getAssetRef = useRef(new Subscription());
	const deleteAssetRef = useRef(new Subscription());
	const callAssetSelectedRef = useRef(new Subscription());

	const handleRedirectToPayment = useCallback(
		(paymentMethod: string, paymentInfo: PaymentInfoModel | null) => {
			if (paymentInfo) {
				const { uuid, status } = paymentInfo;
				const [paymentSegment, selectedPaymentMethod] =
					PaymentUtil.getPaymentMethodAndSegmentPair(paymentMethod);

				// update selectedPaymentMethod in context and LS
				setSelectedPaymentMethod(selectedPaymentMethod, paymentInfo, setPaymentInfo);

				if (status === PAYMENT_LINK_STATUS.VALID) {
					navigate(`/uuid/${uuid}/${paymentSegment}`);
				}
			} else {
				setError({ ...defaultError });
			}
		},
		[setPaymentInfo, navigate, setError]
	);

	const pageLoadGetAssetCallback = useCallback(
		(assets, paymentLinkInfoResponse) => {
			setAssets(assets);
			skipLandingPageIfPossible(paymentLinkInfoResponse, handleRedirectToPayment, assets);
		},
		[handleRedirectToPayment]
	);

	const reloadAssetsCallback = useCallback((assets) => {
		setAssets(assets);
	}, []);

	const getAssets = useCallback(
		(callback, paymentLink) => {
			dialogService.openDialog(DIALOGS.LOADER);
			if (paymentLink) {
				if (getAssetRef.current) {
					getAssetRef.current.unsubscribe();
				}
				getAssetRef.current = paymentRepository.getAssets(paymentLink).subscribe({
					next: (assets) => {
						callback(assets, paymentLink);

						dialogService.closeDialog(DIALOGS.LOADER);
					},
					error: (err) => {
						dialogService.closeDialog(DIALOGS.LOADER);
						setError({ ...defaultError, concreteError: err });
					},
				});
			}
		},
		[setError]
	);

	const getAssetsOnPageLoad = useCallback(
		(paymentLinkInfoResponse: PaymentLinkInfoResponse) => {
			return getAssets(pageLoadGetAssetCallback, paymentLinkInfoResponse);
		},
		[getAssets, pageLoadGetAssetCallback]
	);

	const reloadAssets = useCallback(() => {
		getAssets(reloadAssetsCallback, appState.paymentInfo);
	}, [appState.paymentInfo, getAssets, reloadAssetsCallback]);

	const deleteAsset = (assetId: string, uuid: string, assetType: string) => {
		if (deleteAssetRef.current) {
			deleteAssetRef.current.unsubscribe();
		}
		deleteAssetRef.current = paymentRepository
			.deleteAsset(
				assetId,
				uuid,
				ASSET_TYPE_TO_ASSET_DTO_TYPE[assetType as ASSET_TYPE].toLowerCase()
			)
			.subscribe({
				next: () => {
					reloadAssets();
					dialogService.closeDialog(DIALOGS.CONFIRM_DELETE_ASSETS);
				},
				error: (err) => {
					dialogService.closeDialog(DIALOGS.LOADER);
					setError({ ...defaultError, concreteError: err });
					dialogService.closeDialog(DIALOGS.CONFIRM_DELETE_ASSETS);
				},
			});
	};

	const callAssetSelected = (asset: AssetType) => {
		if (!appState.paymentInfo) {
			setError({ ...defaultError });

			return new Subscription();
		}

		dialogService.openDialog(DIALOGS.LOADER);

		if (callAssetSelectedRef.current) {
			callAssetSelectedRef.current.unsubscribe();
		}

		const { uuid } = appState.paymentInfo;

		callAssetSelectedRef.current = paymentRepository.callAssetSelected(asset, uuid).subscribe({
			next: () => {
				dialogService.openDialog(DIALOGS.LOADER);
				if (asset.type === ASSET_TYPE.SEPA_DD) {
					navigate({ pathname: `/uuid/${uuid}/success`, search: '?sepaAssetSelected=1' });
				} else {
					navigate({ pathname: `/uuid/${uuid}/success`, search: '?billingAgreementSelected=1' });
				}
			},
			error: () => {
				dialogService.closeDialog(DIALOGS.LOADER);
				navigate(`/uuid/${uuid}/failure`);
			},
		});
	};

	const payWithGppAsset = (assetId: string) => {
		dialogService.openDialog(DIALOGS.LOADER);

		if (!appState.paymentInfo) {
			setError({ ...defaultError });

			return new Subscription();
		}

		const { uuid } = appState.paymentInfo;
		const payload: CreateGppTransactionPayload = {
			payment: {
				asset: assetId,
			},
			deviceInformation: deviceInfo,
		};

		if (createCardTransactionRef.current) {
			createCardTransactionRef.current.unsubscribe();
		}

		createCardTransactionRef.current = paymentRepository
			.createCardTransaction(uuid, payload)
			.subscribe({
				next: (response: CreateGppTransactionResponse) => {
					const transactionInPending =
						response.status === GPP_TRANSACTION_STATUS.PENDING && response.asynchronous;

					if (
						response.status === GPP_TRANSACTION_STATUS.AUTHORIZED ||
						response.status === GPP_TRANSACTION_STATUS.COMPLETED
					) {
						dialogService.openDialog(DIALOGS.LOADER);
						navigate(`/uuid/${uuid}/success`);
					} else if (transactionInPending && response.asynchronous.threeDSecure2Fingerprint) {
						navigate({
							pathname: `/uuid/${uuid}/card-challenge`,
							search: `?threeDSecure2Fingerprint=${response.asynchronous.threeDSecure2Fingerprint}`,
						});
					} else if (transactionInPending && response.asynchronous.threeDSecure2Challenge) {
						navigate({
							pathname: `/uuid/${uuid}/card-challenge`,
							search: `?threeDSecure2Challenge=${response.asynchronous.threeDSecure2Challenge}`,
						});
					} else {
						navigate({
							pathname: `/uuid/${uuid}/failure`,
						});
						dialogService.closeDialog(DIALOGS.LOADER);
					}
				},
				error: () => {
					dialogService.closeDialog(DIALOGS.LOADER);
					navigate({
						pathname: `/uuid/${uuid}/failure`,
					});
				},
			});
	};

	const payWithSepaMandate = (mandateId: string) => {
		dialogService.openDialog(DIALOGS.LOADER);

		if (!appState.paymentInfo) {
			setError({ ...defaultError });

			return new Subscription();
		}

		const { uuid } = appState.paymentInfo;

		if (payWithSepaMandateRef.current) {
			payWithSepaMandateRef.current.unsubscribe();
		}

		payWithSepaMandateRef.current = paymentRepository
			.createSepaTransaction(uuid, mandateId)
			.subscribe({
				next: (response) => {
					if (response.status === SEPA_TRANSACTION_STATUS.PENDING) {
						dialogService.openDialog(DIALOGS.LOADER);
						navigate(`/uuid/${uuid}/success`);
					}
				},
				error: () => {
					navigate(`/uuid/${uuid}/failure`);
					dialogService.closeDialog(DIALOGS.LOADER);
				},
			});
	};

	const payWithPayPalAsset = (assetId: string) => {
		dialogService.openDialog(DIALOGS.LOADER);

		if (!appState.paymentInfo) {
			setError({ ...defaultError });

			return new Subscription();
		}

		const { uuid } = appState.paymentInfo;
		const payload: CreatePayPalTransactionPayload = {
			asset: assetId,
			correlationId: appState.correlationId,
		};

		if (payWithPPAssetRef.current) {
			payWithPPAssetRef.current.unsubscribe();
		}

		payWithPPAssetRef.current = paymentRepository.createPayPalTransaction(uuid, payload).subscribe({
			next: (response: CreatePayPalTransactionResponseDto) => {
				if (
					response.status === GPP_TRANSACTION_STATUS.AUTHORIZED ||
					response.status === GPP_TRANSACTION_STATUS.COMPLETED
				) {
					dialogService.openDialog(DIALOGS.LOADER);
					navigate(`/uuid/${uuid}/success`);
				} else {
					navigate({
						pathname: `/uuid/${uuid}/failure`,
					});
					dialogService.closeDialog(DIALOGS.LOADER);
				}
			},
			error: () => {
				navigate(`/uuid/${uuid}/failure`);
				dialogService.closeDialog(DIALOGS.LOADER);
			},
		});
	};

	const getPaymentLinkInfo = useCallback(
		(uuid: string, { success, error }: CallbackObj<PaymentLinkInfoResponse>) => {
			dialogService.openDialog(DIALOGS.LOADER);

			if (getPaymentLinkInfoRef.current) {
				getPaymentLinkInfoRef.current.unsubscribe();
			}
			getPaymentLinkInfoRef.current = paymentRepository.getPaymentLinkInfo(uuid).subscribe({
				next: success,
				error: error,
			});
		},
		[]
	);

	const appProviderValue: AppContextModel = {
		paymentInfo: appState.paymentInfo,
		error: appState.error,
		assets: appState.assets,
		correlationId: appState.correlationId,
		getPaymentLinkInfo,
		setPaymentInfo,
		setError,
		clearError,
		getAssetsOnPageLoad,
		reloadAssets,
		deleteAsset,
		callAssetSelected,
		selectAsset,
		setCorrelationId,
		payWithGppAsset,
		payWithSepaMandate,
		handleRedirectToPayment,
		payWithPayPalAsset,
	};

	return <AppContext.Provider value={appProviderValue}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
