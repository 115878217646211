import SepaIcon from '../assets/images/sepa.svg';
import PayPalIcon from '../assets/images/paypal.svg';
import {
	CARD_TYPE,
	CARD_TYPE_RECORD,
	getCardPayNowOrAuthorizeBtnText,
	getPayPalPayNowOrAuthorizeBtnText,
	getSepaPayNowOrSelectBtnText,
} from './payment.util';
import {
	PaymentLinkInfoResponse,
	CreditCardAsset,
	SepaAsset,
	PayPalAsset,
	Assets,
	AUTHENTICATION_STATUS,
	TRANSACTION_TYPE_STRING,
	PAYPAL_TRANSACTION_TYPE,
} from '../data/types';
import TrashIcon from '../assets/images/trash.svg';
import AuthorizeIcon from '../assets/images/authorize.svg';
import CartIcon from '../assets/images/cart.svg';
import AddAssetIcon from '../assets/images/add-asset.svg';
import { PaymentInfoModel } from '../store/app-context';

export type AssetConfig = {
	icon: string;
	buttonConfig: {
		payWithAssetButtonConfig: ButtonConfig;
		addAssetButtonConfig: ButtonConfig;
		deleteAssetButtonConfig: ButtonConfig;
	};
	confirmDeleteAssetDialogConfig: DeleteAssetDialogButtonConfig;
	displayName: string;
	assetDisplayId: string;
};

interface ButtonConfig {
	iconType: string;
	btnText: string;
	hidden: boolean;
}

type DeleteAssetDialogButtonConfig = {
	assetId: string;
	assetHolder: string;
	assetNumber: string;
	title: string;
	bodyText: string;
	confirmButtonText: string;
	cancelButtonText: string;
	assetType: string;
};

//Bind FE application asset type to PAYMENT_METHODS types for convenience
export enum ASSET_TYPE {
	SEPA_DD = 'SEPA_DD',
	CARD = 'CARD',
	PAYPAL = 'PAYPAL',
}

//Separate enum for dto asset type
export enum ASSET_DTO_TYPE {
	SEPA = 'SEPA',
	CREDITCARD = 'CREDITCARD',
	PAYPAL = 'PAYPAL',
}

//Create mappings to convert between types easily
export const ASSET_DTO_TYPE_TO_ASSET_TYPE = {
	[ASSET_DTO_TYPE.CREDITCARD]: ASSET_TYPE.CARD,
	[ASSET_DTO_TYPE.SEPA]: ASSET_TYPE.SEPA_DD,
	[ASSET_DTO_TYPE.PAYPAL]: ASSET_TYPE.PAYPAL,
};

export const ASSET_TYPE_TO_ASSET_DTO_TYPE = {
	[ASSET_TYPE.CARD]: ASSET_DTO_TYPE.CREDITCARD,
	[ASSET_TYPE.SEPA_DD]: ASSET_DTO_TYPE.SEPA,
	[ASSET_TYPE.PAYPAL]: ASSET_DTO_TYPE.PAYPAL,
};

export const SUPPORTED_ASSETS_TYPES = [ASSET_TYPE.CARD, ASSET_TYPE.SEPA_DD, ASSET_TYPE.PAYPAL];

/**
 * Retrieves the allowed asset types based on the given payment link configuration.
 * @param paymentLinkInfo - Information about the payment link configuration.
 * @returns An array of allowed asset types as per the given configuration.
 */
export const getAllowedAssetTypes = (paymentLinkInfo: PaymentLinkInfoResponse) => {
	let allowedAssets = SUPPORTED_ASSETS_TYPES;

	// if paypalMerchantId is null remove PayPal BA
	if (!paymentLinkInfo.paypalMerchantId) {
		allowedAssets = allowedAssets.filter((asset) => asset !== ASSET_TYPE.PAYPAL);
	}

	return allowedAssets;
};

const getCardAssetConfig: (asset: CreditCardAsset, paymentInfo: PaymentInfoModel) => AssetConfig = (
	asset,
	paymentInfo
) => {
	const getCardIcon = (asset: CreditCardAsset) => {
		const iconConfig = CARD_TYPE_RECORD[asset.brand];

		return iconConfig
			? CARD_TYPE_RECORD[asset.brand].icon
			: CARD_TYPE_RECORD[CARD_TYPE.UNKNOWN].icon;
	};

	const formatCardNumber = (cardNumber: string): string => {
		const result = '**** ' + cardNumber;

		return result;
	};

	const payWithAssetButtonConfig = {
		iconType:
			paymentInfo.paymentSettings?.card?.transactionType ===
			TRANSACTION_TYPE_STRING.preauthAndCapture
				? CartIcon
				: AuthorizeIcon,
		btnText: getCardPayNowOrAuthorizeBtnText(paymentInfo),
		hidden: false,
	};

	const addAssetButtonConfig = {
		iconType: AddAssetIcon,
		btnText: 'cardAssets.addCard',
		hidden: false,
	};

	const deleteAssetButtonConfig = {
		iconType: TrashIcon,
		btnText: 'cardAssets.delete',
		hidden: false,
	};

	const confirmDeleteAssetDialogConfig = {
		assetId: asset.id,
		assetHolder: asset.holder,
		assetNumber: formatCardNumber(asset.lastDigits),
		title: 'confirmDeleteAssetDialog.title',
		bodyText: 'confirmDeleteAssetDialog.message',
		confirmButtonText: 'confirmDeleteAssetDialog.confirm',
		cancelButtonText: 'confirmDeleteAssetDialog.cancel',
		assetType: asset.type,
	};

	return {
		icon: getCardIcon(asset),
		buttonConfig: {
			payWithAssetButtonConfig,
			addAssetButtonConfig,
			deleteAssetButtonConfig,
		},
		confirmDeleteAssetDialogConfig,
		displayName: asset.holder,
		assetDisplayId: formatCardNumber(asset.lastDigits),
	};
};

const getSepaAssetConfig: (asset: SepaAsset, paymentInfo: PaymentInfoModel) => AssetConfig = (
	asset,
	paymentInfo
) => {
	const { amount, isSubscription } = paymentInfo;

	const formatSepaIban = (iban: string): string => {
		const result = '**** ' + iban.slice(-4);

		return result;
	};

	const payWithAssetButtonConfig = {
		iconType: amount === 0 ? AuthorizeIcon : CartIcon,
		btnText: getSepaPayNowOrSelectBtnText(amount),
		hidden: amount === 0 && !isSubscription,
	};

	const addAssetButtonConfig = {
		iconType: AddAssetIcon,
		btnText: 'sepaAssets.addMandate',
		hidden: false,
	};

	const deleteAssetButtonConfig = {
		iconType: TrashIcon,
		btnText: 'cardAssets.delete',
		hidden: false,
	};

	const confirmDeleteAssetDialogConfig = {
		assetId: asset.id,
		assetHolder: asset.accountHolder,
		assetNumber: formatSepaIban(asset.iban),
		title: 'sepa.confirmDeleteAssetDialog.title',
		bodyText: 'sepa.confirmDeleteAssetDialog.message',
		confirmButtonText: 'sepa.confirmDeleteAssetDialog.confirm',
		cancelButtonText: 'confirmDeleteAssetDialog.cancel',
		assetType: asset.type,
	};

	return {
		icon: SepaIcon,
		buttonConfig: {
			payWithAssetButtonConfig,
			addAssetButtonConfig,
			deleteAssetButtonConfig,
		},
		confirmDeleteAssetDialogConfig,
		displayName: asset.accountHolder,
		assetDisplayId: formatSepaIban(asset.iban),
	};
};

const getPayPalAssetConfig: (asset: PayPalAsset, paymentInfo: PaymentInfoModel) => AssetConfig = (
	asset,
	paymentInfo
) => {
	const payWithAssetButtonConfig = {
		iconType:
			paymentInfo.paymentSettings?.payPal?.transactionType ===
			PAYPAL_TRANSACTION_TYPE.preauthAndCapture
				? CartIcon
				: AuthorizeIcon,
		btnText: getPayPalPayNowOrAuthorizeBtnText(paymentInfo),
		hidden: false,
	};

	const addAssetButtonConfig = {
		iconType: AddAssetIcon,
		btnText: 'paypalAssets.addNew',
		hidden: false,
	};

	const deleteAssetButtonConfig = {
		iconType: TrashIcon,
		btnText: 'cardAssets.delete',
		hidden: false,
	};

	const confirmDeleteAssetDialogConfig = {
		assetId: asset.id,
		assetHolder: asset.customerAccountId,
		assetNumber: asset.billingAgreementId,
		title: 'paypal.confirmDeleteAssetDialog.title',
		bodyText: 'paypal.confirmDeleteAssetDialog.message',
		confirmButtonText: 'paypal.confirmDeleteAssetDialog.confirm',
		cancelButtonText: 'confirmDeleteAssetDialog.cancel',
		assetType: asset.type,
	};

	return {
		icon: PayPalIcon,
		buttonConfig: {
			payWithAssetButtonConfig,
			addAssetButtonConfig,
			deleteAssetButtonConfig,
		},
		confirmDeleteAssetDialogConfig,
		displayName: asset.customerAccountId,
		assetDisplayId: asset.billingAgreementId,
	};
};

export const ASSET_DATA: Record<
	ASSET_TYPE,
	(asset: any, paymentInfo: PaymentInfoModel) => AssetConfig
> = {
	[ASSET_TYPE.CARD]: getCardAssetConfig,
	[ASSET_TYPE.SEPA_DD]: getSepaAssetConfig,
	[ASSET_TYPE.PAYPAL]: getPayPalAssetConfig,
};

/**
 * Checks if there are any assets of supported types in the given assets object.
 * @param assets - An object containing different types of assets.
 * @returns `true` if there are assets of supported types, `false` otherwise.
 */
export const areThereAnyAssets = (assets: Assets | null): boolean => {
	return !!assets && SUPPORTED_ASSETS_TYPES.some((assetType) => assets[assetType].length > 0);
};

export const isAssetAuthStatusIncorrect = (asset: any) => {
	return (
		asset.type === ASSET_DTO_TYPE.CREDITCARD &&
		asset.data?.authenticationStatus === AUTHENTICATION_STATUS.NOAUTH
	);
};
