import { ReactNode, lazy, useContext, useEffect, useMemo, useState } from 'react';
import { shouldLoadFraudNetLibrary } from '../../../../util/payment.util';
import AppContext, { PaymentInfoModel } from '../../../../store/app-context';
import { createGuid } from '../../../../util/general.util';

const FraudNetLibrary = lazy(() => import('./FraudNetLibrary'));

type FraudNetLibraryFacadeProps = {
	children: ReactNode;
};

export default function FraudNetLibraryFacade({ children }: FraudNetLibraryFacadeProps) {
	const [doLoadFraudnetLibrary, setDoLoadFraudnetLibrary] = useState(false);
	const { paymentInfo, assets, correlationId, setCorrelationId } = useContext(AppContext);
	const { isSubscription, selectedPaymentMethod, amount, paypalMerchantId } =
		paymentInfo || ({} as PaymentInfoModel);

	const loadFraudnetLibrary = useMemo(
		() => shouldLoadFraudNetLibrary(assets, selectedPaymentMethod, isSubscription, amount),
		[assets, selectedPaymentMethod, isSubscription, amount]
	);

	const flowId = useMemo(() => `${paypalMerchantId}_landing-page`, [paypalMerchantId]);

	useEffect(() => {
		if (correlationId) {
			setDoLoadFraudnetLibrary(true);
		}
	}, [correlationId]);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (loadFraudnetLibrary) {
			setCorrelationId(createGuid());
		}
	}, [loadFraudnetLibrary]);

	return (
		<>
			{doLoadFraudnetLibrary && correlationId && paypalMerchantId && (
				<FraudNetLibrary {...{ correlationId, flowId }} />
			)}
			{children}
		</>
	);
}
