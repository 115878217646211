export const CUSTOM_CSS_GPP_FORM = `
                    .submit_button {
                        background-color: #0078d6;
                        color: #ffffff;
                        border: 1px solid #0078d6;
                        padding: 15px 32px;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 24px;
                        border-radius: 2px;
                        height: 56px;
                        min-width: 200px;
                        outline: none !important;
                        margin-right: 0;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                    @media (max-width: 479px)  {
                        .submit_button {
                            margin-top: 16px;
                        }
                    }
                    @media (min-width: 480px) {
                        .submit_button {
                            width: 200px;
                            min-width: unset;
                            margin-top: 48px;
                        }
                    }
                    .submit_button:hover {
                        background-color: #004B85;
                        border-color: #004B85;
                    }
                    .submit_button:focus {
                        background-color: #0062AD !important;
                        border-color: #0062AD !important;
                    }
                    .submit_button:active {
                        background-color: #0062AD !important;
                        border-color: #0062AD !important;
                    }
                    .back_button {
                        background-color: #ffffff;
                        color: #333333;
                        border: 1px solid #333333;
                        padding: 15px 32px;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 24px;
                        border-radius: 2px;
                        height: 56px;
                        min-width: 200px;
                        outline: none !important;
                        margin-right: 0;
                        margin-top: 48px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        margin-right:50px;
                    }
                    @media (min-width: 540px) {
                        .back_button {
                            width: 200px;
                            min-width: unset;
                            margin-right: 140px;
                    }
                    }
                    .back_button:hover {
                        background-color: #e0e0e0;
                    }
                    .back_button:focus {
                        background-color: #f8f8f8 !important;
                    }
                    .back_button:active {
                        background-color: #f8f8f8 !important;
                    }
                    .wb-e-inp-1 {
                        margin-top: 12px;
                    }
                    .wb-e-inp-1__label {
                        font-size: 12px;
                        color: #767676;
                        letter-spacing: 0.36px;
                        line-height: 16px;
                        padding-bottom: 3px;
                    }
                    .wb-e-inp-1--has-error .wb-e-inp-1__label {
                        color: #d92121;
                    }
                    .wb-e-inp-1__input {
                        background-color: #f8f8f8;
                        border: 1px solid #bbbbbb;
                        background-image: none;
                        border-radius: 0;
                        color: #333333;
                        display: block;
                        height: 48px;
                        line-height: 24px;
                        padding: 0 14px;
                        width: 100%;
                        font-size: 16px;
                    }
                    .wb-e-inp-1__input:active {
                        border: 1px solid #0078d6;
                    }
                    .wb-e-inp-1__input:focus {
                        border: 1px solid #0078d6;
                    }
                    .wb-e-inp-1--has-error .wb-e-inp-1__input {
                        border-color: #d92121;
                    }
                    .wb-e-dropd-3 {
                        margin-top: 12px;
                        margin-bottom: 0;
                    }
                    .wb-e-dropd-3__label {
                        font-size: 12px;
                        color: #767676;
                        letter-spacing: 0.36px;
                        line-height: 16px;
                        padding-bottom: 3px;
                        margin-bottom: 0;
                    }
                    .wb-e-dropd-3--has-error .wb-e-dropd-3__label {
                        color: #d92121;
                    }
                    .wb-e-dropd-3__btn {
                        background-color: #f8f8f8;
                        border: 1px solid #bbbbbb;
                        color: #696969;
                        border-radius: 0;
                        box-sizing: border-box;
                        cursor: pointer;
                        height: 48px;
                        padding-left: 14px;
                        padding-right: 40px;
                        text-align: left;
                        width: 100%;
                        font-size: 16px;
                        outline: none !important;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                    .wb-e-dropd-3--has-error {
                        margin-top: 12px;
                    }
                    .wb-e-dropd-3--has-error .wb-e-dropd-3__btn {
                        border: 1px solid #d92121;
                        outline: none !important;
                    }
                    .wb-e-dropd-3 .wb-e-dropd-3__btn:active {
                        outline: none !important;
                        border: 1px solid #0078d6;
                    }
                    .wb-e-dropd-3 .wb-e-dropd-3__btn:focus {
                        outline: none !important;
                        border: 1px solid #0078d6;
                    }
                    .wb-e-dropd-3__btn .wb-e-dropd-3__default-text {
                        color: #999999;
                    }
                    .wb-e-inp-1__error-message, .wb-e-inp-2__error-message {
                        color: #d92121;
                        font-size: 12px;
                        letter-spacing: 0.36px;
                        line-height: 16px;
                        margin-top: 3px;
                    }
                    .wb-e-dropd-1__error-message, .wb-e-dropd-2__error-message, .wb-e-dropd-3__error-message {
                        color: #d92121;
                        font-size: 12px;
                        letter-spacing: 0.36px;
                        line-height: 16px;
                        margin-top: 3px;
                    }
                    .wb-e-check-1__label{
                        font-weight:bold;
                    }

                    .wb-content-grid form {
                        padding:0px;
                    }

                    .wb-col-mq2-9 {
                        width:100%;
                    }
                    .wb-col-mq2-9 {
                        max-width: 540px;
                        margin: auto;
                        float: none;   
                        overflow: hidden;                  
                    } 
                    .wb-col-mq2-12 {
                        max-width: 540px;
                        margin: auto;
                        float: none;      
                        overflow: hidden;                  
                    }
                    .wb-e-check-1__checkbox:checked + .wb-e-check-1__icon {
                        background-color: #0078d6;
                        fill: #ffffff; 
                        visibility: visible;
                        width: 20px;
                        height: 20px;
                        border-radius: 0; 
                        border: 4px solid #0078d6;
                        top: 2px;
                        left: 2px;
                    }
                    .wb-e-check-1__checkbox:checked ~ .wb-e-check-1__label::before {
                        border-color: #0078d6; 
                    }
                    .wb-e-check-1__label::before {
                        width: 24px;
                        height: 24px;
                        border: 1px solid #9f9f9f;
                        background-color: #f8f8f8;
                    }
                    `;
