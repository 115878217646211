import { FormControlClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';

export const CustomFormControl: Partial<OverridesStyleRules<FormControlClassKey>> | undefined = {
	marginDense: {
		'&.customInput': {
			marginTop: '0px',
			marginBottom: '0px',

			'& .MuiInput-formControl': {
				height: '2.5rem',
				marginTop: '1rem',
			},
			'& .MuiInput-underline': {
				'&:before': {
					border: 'none !important',
				},
				'&:after': {
					border: 'none !important',
				},
			},
			'& .MuiInputBase-inputMarginDense': {
				padding: '1rem 0 0 0',
			},
			'& .MuiInputLabel-marginDense': {
				transform: 'translate(0px, 13px) scale(1)',
				color: `${getColor('inputContrast')} !important`,
			},
			'& .MuiInputLabel-shrink': {
				transform: 'translate(0px, 1.5px) scale(0.75)',
			},
		},
	},
};
