import { CardClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';
import { inIframe } from '../../../../util/general.util';

export const CustomCard: Partial<OverridesStyleRules<CardClassKey>> | undefined = {
	root: {
		backgroundColor: getColor('card'),
		borderRadius: 0,
		color: getColor('cardContrast'),
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1)',

		'&.detailPage': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			maxWidth: '1232px',
		},

		'&.paymentMethods': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			marginBottom: inIframe() ? '0' : '78px',
		},

		'&.adyen': {
			padding: '20px 16px 16px 16px',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',

			'& .ideal': {
				flexFlow: 'row wrap',
				justifyContent: 'center',
				alignItems: 'space-between',
				width: '100%',

				// '& .adyen-checkout__button': {
				//   marginTop: '200px',
				//
				//   '@media screen and (min-width: 745px)': {
				//     marginTop: '20px',
				//   },
				// },
			},

			'& .bancontact_card': {
				flexFlow: 'row wrap',
				justifyContent: 'center',
				alignItems: 'space-between',
				width: '100%',

				'& .adyen-checkout__button': {
					marginTop: '100px',

					'@media screen and (min-width: 745px)': {
						marginTop: '20px',
					},
				},
			},

			'& .kcp': {
				flexFlow: 'column wrap',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',

				'& .adyen-checkout__button': {
					marginBottom: '300px',

					'@media screen and (min-width: 745px)': {
						margin: '50px 0',
					},
				},
			},

			'& .bancontact_mobile': {
				flexFlow: 'column wrap',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',

				'& .adyen-checkout__button': {
					marginBottom: '300px',

					'@media screen and (min-width: 745px)': {
						margin: '50px 0',
					},
				},
			},

			'@media screen and (min-width: 745px)': {
				padding: '40px 65px',
			},
		},
	},
};
