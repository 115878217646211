import React from 'react';
import styled from '@emotion/styled';
import { getVar } from '../../../theme/ui-variables/ui-variables';
import { getColor } from '../../../theme/colors/colors';
import { getFont } from '../../../theme/fonts/fonts';
import { COUNTRIES, LOCALES } from '../../../../util/payment.util';
import FooterLink, { isInIframe } from './FooterLink';
import {
	IMPRINT_BASE_URL,
	PRIVACY_STATEMENT_BASE_URL,
	createUrl,
} from '../../../../util/general.util';

type FooterProps = {
	locale: LOCALES | null;
};

const Footer = ({ locale }: FooterProps) => {
	const countryCode = locale?.split('_')[1].toUpperCase();

	const getFooterLinks = (): JSX.Element | null => {
		switch (countryCode) {
			case COUNTRIES.KR:
				return null;
			case COUNTRIES.SG:
				return (
					<>
						<FooterLink
							config={{
								url: createUrl(IMPRINT_BASE_URL, locale),
								id: 'footer-imprint-link',
								label: 'footer.imprint',
							}}
						/>
					</>
				);
			default:
				return (
					<>
						<FooterLink
							config={{
								url: createUrl(IMPRINT_BASE_URL, locale),
								id: 'footer-imprint-link',
								label: 'footer.imprint',
							}}
						/>
						<FooterLink
							config={{
								url: createUrl(PRIVACY_STATEMENT_BASE_URL, locale),
								id: 'footer-privacy-policy-link',
								label: 'footer.privacyStatement',
							}}
						/>
					</>
				);
		}
	};

	return <FooterContainer>{getFooterLinks()}</FooterContainer>;
};

export const FooterMemo = React.memo(Footer);

const FooterContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: ${isInIframe ? 'auto 0' : 'auto 0 0 0'};
	padding: 0 18px;
	min-height: ${isInIframe ? '25px' : getVar('footerHeightMobile')};
	background-color: ${isInIframe ? getColor('background') : getColor('headerFooterSplash')};
	font-size: 14px;
	font-family: ${getFont('mainFont')};

	@media only screen and (min-width: 824px) {
		min-height: ${isInIframe ? '25px' : getVar('footerHeight')};
		font-size: 16px;
		padding: 0 104px;
	}
`;
