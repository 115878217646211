import { TextFieldClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';

export const CustomTextField: Partial<OverridesStyleRules<TextFieldClassKey>> | undefined = {
	root: {
		'&.mpay-input': {
			minWidth: '33rem',

			'& .MuiFormLabel-root': {
				paddingTop: '5px',
			},

			'&.MuiFormControl-marginNormal': {
				marginTop: 0,
				marginBottom: 0,
			},

			'& .MuiOutlinedInput-root': {
				minHeight: '4rem',
				color: getColor('inputContrast'),
				backgroundColor: getColor('background'),
				borderRadius: '2px',

				'& .MuiOutlinedInput-input': {
					padding: '0.8rem 1rem',
				},

				'& .MuiOutlinedInput-notchedOutline': {
					borderWidth: '1px',
					borderColor: getColor('background'),
				},
			},

			'& .MuiInputBase-root.Mui-disabled': {
				opacity: 0.6,
			},

			'&.mpay-input-with-currency': {
				'& .MuiOutlinedInput-root': {
					'& .MuiOutlinedInput-input': {
						padding: '1rem 1rem 0.6rem 1rem',
					},

					'& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
						'& .MuiTypography-root': {
							color: getColor('inputContrast'),
							fontSize: '1.8rem',
						},
					},
				},
			},
		},
	},
};
