/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import {
	CURRENCY_CODES,
	LOCALES,
	PAYMENT_LINK_STATUS,
	PAYMENT_METHODS,
	PAYMENT_METHOD_PATH_SEGMENTS,
} from '../util/payment.util';
import { paymentLocalStorageService } from '../core/services/local-storage/payment-link-ls.service';
import { HPPError } from '../util/error.utils';
import { getPathParam, PATH_PARAM_NAME } from '../util/general.util';
import { AssetType, Assets, PaymentLinkInfoResponse, PaymentSettings } from '../data/types';

export type CallbackObj<T> = {
	success: (payload: T) => void;
	error: (err: any) => void;
};

export interface PaymentInfoModel {
	merchantId: string;
	uuid: string;
	amount: number;
	formattedAmount: string | null;
	currency: CURRENCY_CODES | null;
	locale: LOCALES | null;
	returnUrl: string;
	termsAndConditionsUrl: string | null;
	paymentMethods: PAYMENT_METHODS[];
	merchantName: string;
	merchantReference: string;
	skipLandingPage: boolean;
	description: string | null;
	status: PAYMENT_LINK_STATUS | null;
	dateCreated: string;
	paymentSettings: PaymentSettings | null;
	returnUrlTimer: number | null;
	expirationDate: string;
	selectedPaymentMethod: PAYMENT_METHOD_PATH_SEGMENTS | null;
	consumerReference: string | null;
	isSubscription: boolean;
	backToShopUrl: string;
	showAssetsIfSkipLandingPageIsTrue: boolean;
	paypalMerchantId: string;
	requireConsent: boolean;
}

export type AppContextModel = {
	paymentInfo: PaymentInfoModel | null;
	error: HPPError | null;
	assets: Assets | null;
	correlationId: string | null;
	getPaymentLinkInfo: (uuid: string, callbacks: CallbackObj<PaymentLinkInfoResponse>) => void;
	setPaymentInfo: (paymentInfo: PaymentInfoModel) => void;
	setError: (error: HPPError) => void;
	clearError: () => void;
	getAssetsOnPageLoad: (paymentLinkInfoResponse: PaymentLinkInfoResponse) => void;
	reloadAssets: () => void;
	deleteAsset: (assetId: string, consumerReference: string, assetType: string) => void;
	callAssetSelected: (asset: AssetType) => void;
	selectAsset: (assetId: string) => void;
	setCorrelationId: (correlationId: string) => void;
	payWithGppAsset: (assetId: string) => void;
	payWithSepaMandate: (mandateId: string) => void;
	handleRedirectToPayment: (
		paymentMethod: string,
		paymentInfo: PaymentInfoModel | null,
		assets?: Assets | null
	) => void;
	payWithPayPalAsset: (assetId: string) => void;
};

const defaultValue = {
	paymentInfo:
		paymentLocalStorageService.getPayment(
			getPathParam(window.location.pathname, PATH_PARAM_NAME.UUID)
		) || null,
	error: null,
	assets: null,
	correlationId: null,
	getPaymentLinkInfo: (uuid: string) => {},
	setPaymentInfo: (paymentInfo: PaymentInfoModel) => {},
	setError: (error: HPPError) => {},
	clearError: () => {},
	getAssetsOnPageLoad: () => {},
	reloadAssets: () => {},
	deleteAsset: (assetId: string, consumerReference: string, assetType: string) => {},
	callAssetSelected: (asset: AssetType) => {},
	selectAsset: (assetId: string) => {},
	setCorrelationId: (correlationId: string) => {},
	payWithGppAsset: (assetId: string) => {},
	payWithSepaMandate: (mandateId: string) => {},
	handleRedirectToPayment: (paymentMethod: string, paymentInfo: PaymentInfoModel | null) => {},
	payWithPayPalAsset: (assetId: string) => {},
};

const AppContext = createContext<AppContextModel>(defaultValue);

export default AppContext;
