import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';

export const Spinner = () => {
	return (
		<SpinnerContainer>
			<SpinnerSmallContainer>
				<CircularProgress
					id="app-spinner"
					className="spinner"
					sx={{
						animationDuration: '1800ms',
					}}
					thickness={4.5}
				/>
			</SpinnerSmallContainer>
		</SpinnerContainer>
	);
};

const SpinnerContainer = styled.div`
	height: 198px;
	width: 198px;
	display: flex;
	flex-flow: column wrap;
	justify-content: flex-end;
	align-items: center;

	@media only screen and (min-width: 745px) {
		height: 264px;
		width: 264px;
	}
`;

const SpinnerSmallContainer = styled.div`
	height: 96px;
	width: 96px;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;

	@media only screen and (min-width: 745px) {
		height: 138px;
		width: 138px;
	}
`;
