import { MutableRefObject } from 'react';
import { Subscription, interval, take, map, startWith } from 'rxjs';
import { appSetup } from '..';
import { DIALOGS, dialogService } from '../core/services/dialog.service';
import { PaymentInfoModel } from '../store/app-context';
import {
	APP_ORIGIN,
	COUNTRIES,
	LOCALES,
	PAYMENT_LINK_EXPIRATION_INTERVAL_SIZE,
	PAYMENT_LINK_EXPIRATION_SHOW_DIALOG,
	PaymentUtil,
	PAYMENT_METHODS,
} from './payment.util';
import { areThereAnyAssets, ASSET_TYPE } from './assets.util';
import { PaymentLinkInfoResponse, Assets } from '../data/types';
import { HPPError, paymentExpiredError } from './error.utils';

/**
 * Checks whether the application is running in the development or test environment.
 * @returns `true` if the application is in development or test environment, `false` otherwise.
 */
export const isDevOrTest = (): boolean => {
	return (
		appSetup.getConfig().appOrigin === APP_ORIGIN.DEV ||
		appSetup.getConfig().appOrigin === APP_ORIGIN.TST
	);
};

//Footer links base
export const IMPRINT_BASE_URL = 'https://hpp.imprint.mercedes-pay.com';
export const PRIVACY_STATEMENT_BASE_URL = 'https://hpp.privacy.mercedes-pay.com';

/**
 * Creates Imprint and Privacy links based on the provided base URL and locale.
 * @param baseUrl - Base URL for the website.
 * @param locale - Locale code representing language and country (e.g., en_US).
 * @returns A string representing the generated URL for Imprint and Privacy links.
 */
export const createUrl = (baseUrl: string, locale: LOCALES | null): string => {
	let url = '';

	if (locale?.includes('_')) {
		const localeArr = locale.split('_');
		const lang = localeArr[0].toLowerCase();
		const country = localeArr[1].toLowerCase();

		url =
			country === COUNTRIES.SG.toLowerCase()
				? `${baseUrl}/${COUNTRIES.GB.toLowerCase()}/${lang}`
				: `${baseUrl}/${country}/${lang}`;
	}

	return url;
};

/**
 * Checks if the provided pathname corresponds to the landing page based on the presence of '/uuid/' and a valid UUID length.
 * @param pathname - The pathname of the current URL.
 * @returns `true` if the pathname corresponds to a landing page, `false` otherwise.
 */
export const isOnLandingPage = (pathname: string) => {
	// If there aren't any slashes after '/uuid/' in pathname, we're on a landing page
	let isLandingPage = false;
	if (pathname.indexOf('/uuid/') !== -1) {
		const pathnameSecondPart = pathname.split('/uuid/')[1];
		if (pathnameSecondPart.length === 36 || pathnameSecondPart.length === 37) {
			isLandingPage = true;
		}
	}

	return isLandingPage;
};

/**
 * Checks if the current page is loaded inside an iframe.
 * @returns `true` if the page is within an iframe, `false` otherwise.
 */
export const inIframe = () => {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
};

/**
 * Checks if the Local Storage is available and operational in the current browser environment.
 * @returns `true` if Local Storage is available, `false` otherwise.
 */
export const isLocalStorageAvailable = () => {
	let storage = null;
	try {
		storage = window.localStorage;
		const x = '__storage_test__';
		storage.setItem(x, x);
		storage.removeItem(x);

		return true;
	} catch (e) {
		//  e instanceof DOMException &&
		//  everything except Firefox
		//  (e.code === 22 ||
		//  Firefox
		//  e.code === 1014 ||
		//  test name field too, because code might not be present
		//  everything except Firefox
		//  e.name === 'QuotaExceededError' ||
		//  Firefox
		//  e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
		//  acknowledge QuotaExceededError only if there's something already stored
		//  (storage && storage.length !== 0)
		const isAvailable =
			e instanceof DOMException &&
			(e.code === 22 ||
				e.code === 1014 ||
				e.name === 'QuotaExceededError' ||
				e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
			storage?.length !== 0;

		return isAvailable;
	}
};

/**
 * Starts a countdown timer for payment link expiration and triggering actions based on the remaining time.
 * @param expirationDate - The expiration date and time of the payment link.
 * @param uuid - Unique identifier of the payment link.
 * @param countdownRef - Mutable reference object to manage the countdown subscription.
 */
export const startPaymentLinkExpirationTimer = (
	expirationDate: string,
	uuid: string,
	countdownRef: MutableRefObject<Subscription>,
	setError: (error: HPPError) => void
) => {
	const expDate: any = new Date(expirationDate);

	const currentDate: any = new Date();
	let timeRemainingInSeconds = 0;
	if (expDate > currentDate) {
		timeRemainingInSeconds = Math.floor((expDate - currentDate) / 1000);

		// Create an observable which emits a decremented value every second until it reaches zero
		if (countdownRef.current) {
			countdownRef.current.unsubscribe();
		}
		countdownRef.current = interval(PAYMENT_LINK_EXPIRATION_INTERVAL_SIZE)
			.pipe(
				take(timeRemainingInSeconds),
				map((n) => timeRemainingInSeconds - 1 - n),
				startWith(timeRemainingInSeconds)
			)
			.subscribe({
				next: (seconds: number) => {
					if (seconds === PAYMENT_LINK_EXPIRATION_SHOW_DIALOG) {
						dialogService.openDialog(DIALOGS.PAYMENT_EXPIRATION);
					}
					if (isDevOrTest()) {
						console.log(`Payment link will expire in ${seconds} seconds`);
					}
				},
				error: (err) => {
					console.error('An error occurred:', err);
				},
				complete: () => {
					console.info('Payment link expired.');
					dialogService.closeDialog(DIALOGS.PAYMENT_EXPIRATION);
					setError({ ...paymentExpiredError });
				},
			});
	} else {
		dialogService.closeDialog(DIALOGS.PAYMENT_EXPIRATION);
	}
};

/**
 * Determines whether to skip the landing page based on the provided payment link response and configuration settings.
 * If skipping the landing page is possible, it redirects the user to the appropriate payment method.
 * @param response - Payment link response object containing configuration details.
 * @param handleRedirectToPayment - Function to handle redirection to a specific payment method.
 * @param assets - Optional object containing different types of assets.
 */
export const skipLandingPageIfPossible = (
	response: PaymentLinkInfoResponse,
	handleRedirectToPayment: (
		paymentMethod: string,
		paymentInfo: PaymentInfoModel | null,
		assets?: Assets | null
	) => void,
	assets?: Assets
) => {
	const doSkipLandingPage = shouldSkipLandingPage(
		response.skipLandingPage,
		areThereAnyAssets(assets || null),
		response.showAssetsIfSkipLandingPageIsTrue
	);

	if (doSkipLandingPage) {
		const paymentInfo = PaymentUtil.toPaymentInfoModel(response);
		// If skipLandingPage is true, paymentMethods array can contain only ONE payment method
		const paymentMethods = response.paymentMethods;
		const paymentMethod = paymentMethods[0];
		// Redirect user to the appropriate payment method
		handleRedirectToPayment(paymentMethod, paymentInfo, assets);
	}
};

/**
 * Determines whether the landing page should be skipped based on configuration settings and asset availability.
 * @param skipLandingPageSetting - Boolean indicating whether skipping the landing page is enabled in the configuration.
 * @param hasAssets - Boolean indicating whether there are assets available.
 * @param showAssets - Boolean indicating whether assets should be displayed on the landing page.
 * @returns `true` if the landing page should be skipped, `false` otherwise.
 */
export const shouldSkipLandingPage = (
	skipLandingPageSetting: boolean,
	hasAssets: boolean,
	showAssets: boolean
) => {
	let result = false;

	if (skipLandingPageSetting) {
		if (hasAssets) {
			//stay on LP based on showAssets setting
			result = !showAssets;
		} else {
			//go to payment method as there are no assets
			result = true;
		}
	}

	return result;
};

/**
 * Removes duplicates from an array of objects based on specified properties.
 * @param array - An array of objects of generic type T.
 * @param properties - An array of keys (properties) of the objects used to identify duplicates.
 * @returns An array containing unique objects based on the specified properties.
 * @template T - Generic type representing the type of objects in the input array.
 */
export const removeDuplicatesByProperties = <T>(array: T[], properties: (keyof T)[]): T[] => {
	const uniqueSet = new Set<string>();

	return array.filter((asset) => {
		const key = properties.map((property) => asset[property]).join('-');

		if (uniqueSet.has(key)) {
			return false;
		}
		uniqueSet.add(key);

		return true;
	});
};

export enum PATH_PARAM_NAME {
	UUID = 'uuid',
	CKEY = 'ckey',
	MID = 'mid',
}

/**
 * Extracts the value of a specified path parameter from a given URL pathname.
 * @param pathname - The URL pathname from which the path parameter value will be extracted.
 * @param pathParamName - The name of the path parameter to extract.
 * @returns The value of the specified path parameter, or an empty string if the parameter is not found.
 */
export const getPathParam = (pathname: string, pathParamName: PATH_PARAM_NAME): string => {
	if (!pathname.includes(pathParamName)) {
		return '';
	}

	return pathname.split(pathParamName)[1].split('/')[1];
};

/**
 * Validates whether the provided string conforms to the UUID (Universal Unique Identifier) format.
 * @param uuid - The string to be validated as a UUID.
 * @returns `true` if the string is a valid UUID, `false` otherwise.
 */
export const isUuidValid = (uuid: string) => {
	const regexExp =
		/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

	return regexExp.test(uuid);
};

/**
 * Converts a payment method identifier to an asset type enum.
 * @param paymentMethod - Payment method identifier as a string or from the PAYMENT_METHODS enum.
 * @returns Corresponding asset type from the ASSET_TYPE enum.
 */
export const getPaymentMethodAsAssetType = (
	paymentMethod: PAYMENT_METHODS | string
): ASSET_TYPE => {
	return paymentMethod as unknown as ASSET_TYPE;
};

/**
 *
 * @returns 'true' if opened by a mobile browser
 */
export const isMobile = () => {
	const regex =
		/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i;

	return regex.test(navigator.userAgent);
};

/**
 *
 * @returns random generated uuid
 */
export const createGuid = () => {
	function S4() {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	}

	return (
		S4() +
		S4() +
		'-' +
		S4() +
		'-4' +
		S4().substr(0, 3) +
		'-' +
		S4() +
		'-' +
		S4() +
		S4() +
		S4()
	).toLowerCase();
};
