export const INDIAN_METHOD_CUSTOM_CSS_GPP_FORM = `
                    .payu-wrapper {
                        padding: 0px;
                    }
                    .wb-e-inp-1 {
                        margin-top: 12px !important;
                        margin-bottom: 0 !important;
                    } 
                    .wb-e-inp-1__input::placeholder {
                        color: #9bacc8;
                    }
                    .wb-e-inp-3__input {
                        padding-top: 14px!important;
                    }
                    .switch input:checked+span {
                        background-color: #0078d6;
                    }
                    .wb-e-inp-1__label {
                        font-size: 12px;
                        color: #767676;
                        letter-spacing: 0.36px;
                        line-height: 16px;
                        padding-bottom: 3px;
                    }
                    .wb-e-inp-1--has-error .wb-e-inp-1__label {
                        color: #d92121;
                    }
                    .wb-e-inp-1__input, .wb-e-inp-3__input{
                        background-color: #f8f8f8;
                        border: 1px solid #bbbbbb;
                        background-image: none;
                        border-radius: 0;
                        color: #333333;
                        display: block;
                        height: 48px;
                        line-height: 24px;
                        padding: 0 14px;
                        width: 100%;
                        font-size: 16px;
                    }
                    .wb-e-inp-1__input:active {
                        border: 1px solid #0078d6;
                    }
                    .wb-e-inp-1__input:focus {
                        border: 1px solid #0078d6;
                    }
                    .wb-e-inp-1--has-error .wb-e-inp-1__input {
                        border-color: #d92121;
                    }
                    form .wb-content-grid__row .submit_button {
                        width: 100% !important;
                        min-width: unset;
                        background-color: #ffffff;
                        color: #333333;
                        border: 1px solid #333333;
                        padding: 15px 32px;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 24px;
                        border-radius: 2px;
                        height: 56px;
                        outline: none !important;
                        margin-right: 0;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;   
                        
                        &:hover {
                            background-color: #e0e0e0;
                        }
                        &:focus {
                            background-color: #f8f8f8 !important;
                        }
                        &:active {
                            background-color: #f8f8f8 !important;
                        }
                    }

                    form .wb-content-grid__row:nth-child(9) .submit_button{
                        margin-top: 16px;   
                        margin-bottom: 16px;
                    }

                    form .wb-content-grid__row:nth-child(11) .submit_button{
                        margin-bottom: 16px;
                    }

                    form .wb-content-grid__row:nth-last-child(1):nth-child(9) .submit_button {
                        margin-top: 16px;   
                    }

                    form .wb-content-grid__row:last-child .submit_button  {
                        background-color: #0078d6;
                        color: #ffffff;
                        border: 1px solid #0078d6;
                        
                        &:hover {
                            background-color: #004B85;
                            border-color: #004B85;
                        }
                        &:focus {
                            background-color: #0062AD !important;
                            border-color: #0062AD !important;
                        }
                        &:active {
                            background-color: #0062AD !important;
                            border-color: #0062AD !important;
                        }
                    }
                    .billing-address {
                        margin-top: 20px;
                    }
                    .consent-switch__container {
                        margin-top: 20px;
                    }   
                    .consent-description {
                        font-size: 14px !important;
                    }
                    .switch-label {
                        font-size: 16px !important;
                        font-family: DaimlerCS-Regular,sans-serif !important;
                        font-weight: bold !important;
                        padding-top: 5px;
                    }
                                 
`;
