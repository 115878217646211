import { ASSET_DTO_TYPE, ASSET_TYPE } from '../../util/assets.util';
import { CARD_TYPE } from '../../util/payment.util';

export enum AUTHENTICATION_STATUS {
	NOAUTH = 'NoAuth',
	AUTH = 'Auth',
	AUTH3DS1 = 'Auth3ds1',
	AUTH3DS2 = 'Auth3ds2',
}

export interface AssetDto {
	id: string;
	type: ASSET_DTO_TYPE;
	data: CardData | null;
	sepaData: SepaData | null;
	paypalBillingData: PaypalBillingData | null;
}

export interface AssetsResponseDto {
	defaultAsset: AssetDto;
	data: AssetDto[];
}

export interface DeleteAssetResponseDto {
	id: string;
	objectType: string;
	createdAt: number;
	consentGiven: true;
	type: string;
	invalid: boolean;
	recurring: boolean;
	data: {
		firstDigits: string;
		lastDigits: string;
		expiryMonth: number;
		expiryYear: number;
		brand: string;
		holder: string;
		qualified: boolean;
		expired: boolean;
		authenticationStatus: string;
	};
}

export interface AssetNotificationRequestDto {
	assetType: ASSET_DTO_TYPE;
	paymentLinkId: string;
	id: string;
	sepaData?: SepaData;
	paypalBillingData?: PaypalBillingData;
}

type CardData = {
	lastDigits: string;
	brand: CARD_TYPE;
	holder: string;
	authenticationStatus: AUTHENTICATION_STATUS;
};

type SepaData = {
	iban: string;
	accountHolder: string;
	sepaMandateReference: string;
};

type PaypalBillingData = {
	billingAgreementId: string;
	customerAccountId: string;
	payerEmail: string;
};

export type AssetType = SepaAsset | CreditCardAsset | PayPalAsset;

export interface SepaAsset {
	id: string;
	type: ASSET_TYPE;
	isSelected: boolean;
	iban: string;
	accountHolder: string;
	sepaMandateReference: string;
}

export interface CreditCardAsset {
	id: string;
	type: ASSET_TYPE;
	isSelected: boolean;
	lastDigits: string;
	brand: CARD_TYPE;
	holder: string;
	authenticationStatus: AUTHENTICATION_STATUS;
}

export interface PayPalAsset {
	id: string;
	type: ASSET_TYPE;
	isSelected: boolean;
	billingAgreementId: string;
	customerAccountId: string;
	payerEmail: string;
}

export type Assets = {
	[key in ASSET_TYPE]: key extends ASSET_TYPE.SEPA_DD
		? SepaAsset[]
		: key extends ASSET_TYPE.CARD
		? CreditCardAsset[]
		: key extends ASSET_TYPE.PAYPAL
		? PayPalAsset[]
		: never; // Handle potential missing cases
};
