import { Creditor } from './creditor';

export enum MANDATE_TYPE {
	OOFF = 'OOFF',
	RCUR = 'RCUR',
}

export enum MANDATE_SCHEME {
	SEPADD_CORE = 'SEPADD_CORE',
	SEPADD_B2B = 'SEPADD_B2B',
	SEPADDCORE = 'SEPADDCORE',
	SEPADDB2B = 'SEPADDB2B',
}

export interface CreateSepaTransactionDto {
	id: string;
	provider: string;
	asset: string;
	paymentMethod: string;
	amount: number;
	currency: string;
	createdAt: number;
	status: string;
}

export interface Sepa {
	leadDate: number;
	mandateType: MANDATE_TYPE;
	mandateScheme: MANDATE_SCHEME;
	creditorReferenceParty: string;
}

export type CreateSEPAMandateResponse = {
	mandateId: string;
};

export type CreateSEPAMandateResponseDto = {
	uuid: string;
	iban: string;
	sepaMandateReference: string;
	creditor: Creditor;
	accountHolder: string;
	signatureDate: string;
	signatureLocation: string;
	mandateType: string;
	mandateScheme: string;
	status: string;
	dateCreated: string;
	consumerReference: string;
};

export type CreateSEPAMandatePayload = {
	iban: string;
	accountHolder: string;
	signatureDate: string;
	signatureLocation: string;
	mandateType: MANDATE_TYPE;
	mandateScheme: string;
	consumerReference: string;
	creditorReferenceParty: string;
};
