import styled from '@emotion/styled';
import { getColor } from '../../../theme/colors/colors';
import { useTranslation } from 'react-i18next';
import { inIframe } from '../../../../util/general.util';

export const isInIframe = inIframe();
interface FooterLinkConfig {
	url: string;
	id: string;
	label: string;
}

const FooterLink = ({ config }: { config: FooterLinkConfig }) => {
	const { url, id, label } = config;

	const [t] = useTranslation();

	return (
		<FooterLinkStyled>
			<a href={url} target="_blank" rel="noreferrer" id={id}>
				{t(label)}
			</a>
		</FooterLinkStyled>
	);
};

export default FooterLink;

const FooterLinkStyled = styled.div`
	cursor: pointer;

	& a {
		color: ${isInIframe ? getColor('mainText') : getColor('headerFooterSplashContrast')};
	}
`;
