import React from 'react';
import logoDesktop from '../../../../assets/images/logo-desktop.svg';
import logoSmall from '../../../../assets/images/logo-mobile.svg';
import styled from '@emotion/styled';
import { getVar } from '../../../theme/ui-variables/ui-variables';
import { getColor } from '../../../theme/colors/colors';

const Header = () => {
	return (
		<HeaderContainer>
			<Logo src={logoDesktop} alt="Mercedes Pay Logo" />
			<LogoMobile src={logoSmall} alt="Mercedes Pay Logo" />
		</HeaderContainer>
	);
};

export const HeaderMemo = React.memo(Header);

const HeaderContainer = styled.div`
	width: 100%;
	height: ${getVar('headerHeightMobile')};
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${getColor('headerFooterSplash')};

	@media only screen and (min-width: 810px) {
		height: auto;
		min-height: ${getVar('headerHeight')};
		justify-content: flex-start;
		padding: 0px 104px;
	}
`;

const Logo = styled.img`
	display: none;
	height: 65px;

	@media only screen and (min-width: 810px) {
		display: inline;
	}
`;

const LogoMobile = styled.img`
	display: none;
	height: 31px;

	@media only screen and (max-width: 809px) {
		display: inline;
	}
`;
