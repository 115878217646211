// ******************************************************  TYPE  ****************************************************** //
export type UIVar = keyof typeof uiVariables;

// ******************************************************  FUNCTIONS  ****************************************************** //
export function getVar(v: UIVar) {
	return `var(${uiVariables[v]})`;
}

// ******************************************************  VARIABLES  ****************************************************** //
const uiVariables = {
	mainPadding: '--mainPadding',
	headerHeight: '--headerHeight',
	headerHeightMobile: '--headerHeightMobile',
	footerHeight: '--footerHeight',
	footerHeightMobile: '--footerHeightMobile',
	borderRadius: '--borderRadius',
};
