import { ButtonBaseClassKey, ButtonClassKey, IconButtonClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor, getDarkerColor } from '../../colors/colors';
import { getFont } from '../../fonts/fonts';

export const CustomButton: Partial<OverridesStyleRules<ButtonClassKey>> | undefined = {
	root: {
		letterSpacing: '0.4px',
		boxShadow: 'none',
		fontFamily: getFont('mainFont'),
		borderWidth: '1px',
		borderStyle: 'solid',
		padding: '15px 32px',
		fontSize: '16px',
		fontWeight: 'bold',
		lineHeight: '24px',
		textTransform: 'none',
		borderRadius: '2px',

		'&:hover': {
			boxShadow: 'none',
		},

		'&:active': {
			boxShadow: 'none',
		},

		'&.primary-color-button': {
			backgroundColor: getColor('primary'),
			color: getColor('primaryContrast'),
			borderColor: getColor('primary'),

			'&:hover': {
				backgroundColor: getDarkerColor('primary', 16),
				borderColor: getDarkerColor('primary', 16),
			},

			'&:active': {
				backgroundColor: getDarkerColor('primary', 8),
				borderColor: getDarkerColor('primary', 8),
			},

			'&:disabled': {
				opacity: 0.5,
				cursor: 'not-allowed !important',
			},

			'&.assets-button': {
				padding: '15px 22px',
				width: '100%',
				minWidth: 'unset',
				marginTop: '16px',
				height: '56px',

				'@media screen and (min-width: 510px)': {
					width: '200px',
				},
			},

			'&.back-home-button': {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
			},

			'&.return-button': {
				width: '100%',
				minWidth: 'unset',
				marginTop: '16px',
				height: '56px',

				'@media screen and (min-width: 510px)': {
					maxWidth: '450px',
					marginTop: '0px',
				},
			},

			'&.payment-slip-button': {
				height: '56px',
				width: '280px',
				margin: '15px',

				'@media screen and (min-width: 840px)': {
					width: '329px',
				},
			},
		},

		'&.secondary-color-button': {
			backgroundColor: getColor('secondary'),
			color: getColor('secondaryContrast'),
			borderColor: getColor('secondaryContrast'),

			'&.assets-button': {
				padding: '15px 22px',
				width: '100%',
				minWidth: 'unset',
				marginTop: '16px',
				height: '56px',

				'@media screen and (min-width: 510px)': {
					width: '200px',
					marginBottom: 0,
				},
			},

			'&:hover': {
				backgroundColor: getDarkerColor('secondary', 12),
			},

			'&:active': {
				backgroundColor: getDarkerColor('secondary', 6),
			},

			'&.return-button': {
				width: '100%',
				minWidth: 'unset',
				marginTop: '16px',
				height: '56px',

				'@media screen and (min-width: 510px)': {
					maxWidth: '450px',
					margin: '0px 0px 16px',
				},
			},

			'&.payment-slip-button': {
				height: '56px',
				width: '280px',
				margin: '15px',

				'@media screen and (min-width: 840px)': {
					width: '329px',
				},
			},
		},
	},
};

export const CustomIconButton: Partial<OverridesStyleRules<IconButtonClassKey>> | undefined = {
	root: {
		color: '#333333',
	},
};

export const CustomButtonBase: Partial<OverridesStyleRules<ButtonBaseClassKey>> | undefined = {
	root: {
		backgroundColor: '#ffffff',

		'&.mpay-checkbox': {
			color: '#333333',
			backgroundColor: 'transparent',

			'&.Mui-checked': {
				backgroundColor: 'transparent',
			},
		},
	},
};
