export const lightTheme = {
	primary: '#0078d6',
	primaryContrast: '#ffffff',

	secondary: '#ffffff',
	secondaryContrast: '#333333',

	background: '#f8f8f8',
	backgroundContrast: '#767676',

	headerFooterSplash: '#000000',
	headerFooterSplashContrast: '#f8f8f8',

	paymentInfoHeader: '#262626',
	paymentInfoHeaderContrast: '#ffffff',

	card: '#ffffff',
	cardContrast: '#333333',
	cardBorder: '#e8e8e8',

	cardHeader: '#f8f8f8',
	cardHeaderContrast: '#333333',

	breadcrumbActive: '#33333366',
	breadcrumbInactive: '#333333',

	input: '#f8f8f8',
	inputContrast: '#696969',

	dialog: '#ffffff',
	dialogContrast: '#767676',

	checkboxLabel: '#333333',

	heading: '#333333',

	success: '#1d861d',
	successContrast: '#FFFFFF',

	error: '#d92121',
	errorContrast: '#FFFFFF',

	info: '#0078d6',
	infoContrast: '#FFFFFF',

	spinner: '#ffffff',

	mainText: '#000000',
	helperText: '#9f9f9f',
	cancelButtonBorder: '#bbbbbb',
	inputLabel: '#767676',

	iconBackgroundColorStart: '#f8f8f8',
	iconBackgroundColorEnd: '#ececec',

	tacBorder: '#4eaffd',
};

type ColorTheme = typeof lightTheme;

export const darkTheme: ColorTheme = {
	primary: '#0078d6',
	primaryContrast: '#ffffff',

	secondary: '#ffffff',
	secondaryContrast: '#333333',

	background: '#f8f8f8',
	backgroundContrast: '#767676',

	headerFooterSplash: '#000000',
	headerFooterSplashContrast: '#f8f8f8',

	paymentInfoHeader: '#262626',
	paymentInfoHeaderContrast: '#ffffff',

	card: '#ffffff',
	cardContrast: '#333333',
	cardBorder: '#e8e8e8',

	cardHeader: '#f8f8f8',
	cardHeaderContrast: '#333333',

	breadcrumbActive: '#33333366',
	breadcrumbInactive: '#333333',

	input: '#f8f8f8',
	inputContrast: '#696969',

	dialog: '#ffffff',
	dialogContrast: '#767676',

	checkboxLabel: '#333333',

	heading: '#333333',

	success: '#1d861d',
	successContrast: '#FFFFFF',

	error: '#d92121',
	errorContrast: '#FFFFFF',

	info: '#0078d6',
	infoContrast: '#FFFFFF',

	spinner: '#ffffff',

	mainText: '#ffffff',
	helperText: '#9f9f9f',
	cancelButtonBorder: '#bbbbbb',
	inputLabel: '#767676',

	iconBackgroundColorStart: '#f8f8f8',
	iconBackgroundColorEnd: '#ececec',

	tacBorder: '#4eaffd',
};
