import { createTheme } from '@mui/material/styles';
import createPalette from '@mui/material/styles/createPalette';
import { getColor, getDarkerColor, getLighterColor } from '../colors/colors';
import { CustomFormControl } from './overrides/mui-form-control';
import { CustomDialog } from './overrides/mui-dialog';
import { CustomTextField } from './overrides/mui-text-field';
import { CustomCard } from './overrides/mui-card';
import { CustomButton, CustomButtonBase, CustomIconButton } from './overrides/mui-button';
import { CustomCheckbox } from './overrides/mui-checkbox';
import { getFont } from '../fonts/fonts';
import { CustomCircularProgress } from './overrides/mui-circular-progress';
import { CustomBreadcrumbs } from './overrides/mui-breadcrumbs';

export const muiTheme = createTheme({
	palette: createPalette({
		mode: 'light',
		primary: {
			light: getLighterColor('primary', 20),
			main: getColor('primary'),
			dark: getDarkerColor('primary', 20),
			contrastText: getColor('primaryContrast'),
		},

		secondary: {
			light: getLighterColor('secondary', 10),
			main: getColor('secondary'),
			dark: getDarkerColor('secondary', 20),
			contrastText: getColor('secondaryContrast'),
		},

		error: {
			light: getLighterColor('error', 20),
			main: getColor('error'),
			dark: getDarkerColor('error', 20),
			contrastText: getColor('errorContrast'),
		},

		success: {
			light: getLighterColor('success', 20),
			main: getColor('success'),
			dark: getDarkerColor('success', 20),
			contrastText: getColor('successContrast'),
		},
	}),
	typography: {
		fontFamily: [getFont('mainFont'), 'Roboto', 'sans-serif'].join(','),
		htmlFontSize: 10,
	},
	spacing: 5,
	components: {
		MuiButton: {
			defaultProps: {
				size: 'small',
				disableRipple: true,
			},
			styleOverrides: CustomButton,
		},
		MuiFilledInput: {
			defaultProps: {
				margin: 'dense',
			},
		},
		MuiFormControl: {
			defaultProps: {
				margin: 'dense',
			},
			styleOverrides: CustomFormControl,
		},
		MuiFormHelperText: {
			defaultProps: {
				margin: 'dense',
			},
		},
		MuiIconButton: {
			defaultProps: {
				size: 'small',
			},
			styleOverrides: CustomIconButton,
		},
		MuiInputBase: {
			defaultProps: {
				margin: 'dense',
			},
		},
		MuiListItem: {
			defaultProps: {
				dense: true,
			},
		},
		MuiOutlinedInput: {
			defaultProps: {
				margin: 'dense',
			},
		},
		MuiFab: {
			defaultProps: {
				size: 'small',
			},
		},
		MuiTextField: {
			defaultProps: {
				margin: 'dense',
			},
			styleOverrides: CustomTextField,
		},
		MuiToolbar: {
			defaultProps: {
				variant: 'dense',
			},
		},
		MuiTypography: {
			defaultProps: {
				variant: 'body1',
			},
		},
		MuiSelect: {
			defaultProps: {
				variant: 'standard',
			},
		},
		MuiMenuItem: {
			defaultProps: {
				dense: true,
			},
		},
		MuiMenu: {
			defaultProps: {
				variant: 'menu',
			},
		},
		MuiDialog: {
			styleOverrides: CustomDialog,
		},
		MuiCard: {
			styleOverrides: CustomCard,
		},
		MuiButtonBase: {
			styleOverrides: CustomButtonBase,
		},
		MuiCheckbox: {
			styleOverrides: CustomCheckbox,
		},
		MuiCircularProgress: {
			styleOverrides: CustomCircularProgress,
		},
		MuiBreadcrumbs: {
			styleOverrides: CustomBreadcrumbs,
		},
	},
});
