import { Observable, Subject } from 'rxjs';

export enum DIALOGS {
	LOADER = 'LOADER',
	PAYMENT_EXPIRATION = 'PAYMENT_EXPIRATION',
	CONFIRM_DELETE_ASSETS = 'CONFIRM_DELETE_ASSETS',
}

export type ConfirmDeleteAssetDialogInfo = {
	assetId: string;
	assetHolder: string;
	assetNumber: string;
	title: string;
	bodyText: string;
	confirmButtonText: string;
	cancelButtonText: string;
	assetType: string;
};

type DialogService = {
	openDialog: (dialog: DIALOGS, payload?: DialogMessagePayload) => void;
	closeDialog: (dialog: DIALOGS) => void;
	getDialogSubjectAsObservable: (dialog: DIALOGS) => Observable<DialogMessage>;
};

export type DialogMessagePayload = ConfirmDeleteAssetDialogInfo;

export type DialogMessage = {
	open: boolean;
	payload?: DialogMessagePayload;
};

type DialogSubject = Subject<DialogMessage>;

type DialogSubjectPool = Map<DIALOGS, DialogSubject>;

const dialogSubjectPool: DialogSubjectPool = new Map<DIALOGS, DialogSubject>();

const openDialog = (dialog: DIALOGS, payload?: DialogMessagePayload): void => {
	setDialogOpenState(dialog, true, payload);
};

const closeDialog = (dialog: DIALOGS): void => {
	setDialogOpenState(dialog, false);
};

const setDialogOpenState = (
	dialog: DIALOGS,
	open: boolean,
	payload?: DialogMessagePayload
): void => {
	const dialogMessage: DialogMessage = {
		open,
		...(payload && { payload }),
	};
	getDialogSubject(dialog).next(dialogMessage);
};

const getDialogSubject = (dialog: DIALOGS): DialogSubject => {
	//Make sure the dialog has a corresponding subject for it
	if (!dialogSubjectPool.has(dialog)) {
		dialogSubjectPool.set(dialog, new Subject<DialogMessage>());
	}

	//return the dialog subject from the pool
	return dialogSubjectPool.get(dialog)!;
};

const getDialogSubjectAsObservable = (dialog: DIALOGS): Observable<DialogMessage> => {
	return getDialogSubject(dialog).asObservable();
};

export const dialogService: DialogService = {
	openDialog,
	closeDialog,
	getDialogSubjectAsObservable,
};
