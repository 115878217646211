import { mPayLocalStore } from './local-store-namespace';
import { PaymentInfoModel } from '../../../store/app-context';
import { PAYMENT_LINK_STATUS } from '../../../util/payment.util';

const PAYMENTS = 'payments';

const setPaymentInfo = (newPayment: PaymentInfoModel): void => {
	const allPayments = getPayments();
	if (!allPayments?.length) {
		return mPayLocalStore.set(PAYMENTS, [newPayment]);
	}

	const validPayments = allPayments.filter(
		(payment: PaymentInfoModel) => payment.status === PAYMENT_LINK_STATUS.VALID
	);
	if (!validPayments?.length) {
		return mPayLocalStore.set(PAYMENTS, [newPayment]);
	}

	const existing = validPayments.find(
		(payment: PaymentInfoModel) => payment.uuid === newPayment.uuid
	);
	if (!existing) {
		return mPayLocalStore.set(PAYMENTS, [...validPayments, newPayment]);
	}

	const filtered = validPayments.filter(
		(payment: PaymentInfoModel) => payment.uuid !== newPayment.uuid
	);
	if (!filtered.length) {
		return mPayLocalStore.set(PAYMENTS, [newPayment]);
	}

	return mPayLocalStore.set(PAYMENTS, [...filtered, newPayment]);
};

const getPayments = (): PaymentInfoModel[] => {
	return mPayLocalStore.get(PAYMENTS) || [];
};

const setPayments = (payments: PaymentInfoModel[]) => {
	return mPayLocalStore.set(PAYMENTS, payments);
};

const getPayment = (uuid: string): PaymentInfoModel | undefined => {
	const allPayments = getPayments();
	if (!allPayments?.length) return;

	return allPayments.find((payment: PaymentInfoModel) => payment.uuid === uuid);
};

const deletePayment = (uuid: string): void => {
	const allPayments = getPayments();
	if (allPayments?.length) {
		const newPayments = allPayments.filter((payment: PaymentInfoModel) => payment.uuid !== uuid);
		mPayLocalStore.set(PAYMENTS, newPayments);
	}
};

const deletePayments = (): void => {
	mPayLocalStore.set(PAYMENTS, undefined);
};

export const paymentLocalStorageService = {
	setPaymentInfo,
	getPayments,
	setPayments,
	getPayment,
	deletePayment,
	deletePayments,
};
