import { useEffect, useState } from 'react';
import { DeviceInfo } from '../../data/types';

const defaultDeviceInfo: DeviceInfo = {
	colorDepth: 0,
	screenHeight: 0,
	screenWidth: 0,
	timeZoneOffset: 0,
	javaEnabled: false,
	deviceType: '',
	acceptHeader: '',
	language: 'en',
};

const useDeviceInfo = (): [DeviceInfo] => {
	const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(defaultDeviceInfo);

	useEffect(() => {
		// Function to gather device information
		const getDeviceInfo = () => {
			// Get screen information from window.screen
			const { colorDepth, height: screenHeight, width: screenWidth } = window.screen;

			// Determine whether Java is enabled or not (note: this is deprecated and not reliable)
			const javaEnabled = navigator.javaEnabled ? navigator.javaEnabled() : false;

			let language: string = navigator.language;
			language = language.split('-')[0];

			return {
				colorDepth,
				screenHeight,
				screenWidth,
				timeZoneOffset: new Date().getTimezoneOffset(),
				javaEnabled,
				deviceType: 'browser',
				acceptHeader: '*/*',
				language,
			};
		};

		const deviceInfoObject = getDeviceInfo();

		setDeviceInfo(deviceInfoObject);
	}, []);

	return [deviceInfo];
};

export default useDeviceInfo;
