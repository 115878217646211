import { useCallback, useContext, useEffect, useRef } from 'react';
import './App.scss';
import { getColor, setThemeColor } from './core/theme/colors/colors';
import { ThemeProvider } from '@mui/material';
import { muiTheme } from './core/theme/mui-theme/mui-theme';
import { LoaderMemo } from './core/components/shared/loader/Loader';
import styled from '@emotion/styled';
import { getVar } from './core/theme/ui-variables/ui-variables';
import { FooterMemo } from './core/components/shared/footer/Footer';
import { PaymentLinkExpirationDialogMemo } from './core/components/shared/payment-link-expiration-dialog/PaymentLinkExpirationDialog';
import AppContext, { CallbackObj, PaymentInfoModel } from './store/app-context';
import { HppRoutesMemo } from './core/components/shared/routes/HppRoutes';
import { HeaderMemo } from './core/components/shared/header/Header';
import i18n from './i18n';
import {
	FIELD_TO_UPDATE,
	PAYMENT_LINK_STATUS,
	PaymentUtil,
	SUPPORTED_LANGUAGES_MAP,
} from './util/payment.util';
import {
	skipLandingPageIfPossible,
	getPathParam,
	inIframe,
	isDevOrTest,
	isLocalStorageAvailable,
	isOnLandingPage,
	isUuidValid,
	PATH_PARAM_NAME,
	startPaymentLinkExpirationTimer,
} from './util/general.util';
import { useLocation, useNavigate } from 'react-router-dom';
import { Subscription } from 'rxjs';
import { paymentLocalStorageService } from './core/services/local-storage/payment-link-ls.service';
import { DIALOGS, dialogService } from './core/services/dialog.service';
import { defaultError } from './util/error.utils';
import { PaymentLinkInfoResponse } from './data/types';

export const isInIframe = inIframe();

const App = () => {
	const {
		getPaymentLinkInfo,
		setPaymentInfo,
		setError,
		handleRedirectToPayment,
		clearError,
		getAssetsOnPageLoad,
	} = useContext(AppContext);
	const { locale } = useContext(AppContext).paymentInfo || ({} as PaymentInfoModel);
	const { pathname } = useLocation();
	const countdownRef = useRef(new Subscription());

	const navigate = useNavigate();

	useEffect(() => {
		setThemeColor('light');
	}, []);

	useEffect(() => {
		let language = SUPPORTED_LANGUAGES_MAP.en_GB;
		if (locale) {
			language = SUPPORTED_LANGUAGES_MAP[locale];
		}
		i18n.changeLanguage(language);

		if (isDevOrTest()) {
			console.log('Locale set:', locale);
			console.log('Language set:', i18n.language);
		}
	}, [locale]);

	const handleGetPaymentLinkInfoError = useCallback(
		(err: any) => {
			dialogService.closeDialog(DIALOGS.LOADER);
			setError({ ...defaultError, concreteError: err });
		},
		[setError]
	);

	const handleGetPaymentLinkInfoSuccess = useCallback(
		(paymentLinkInfoResponse: PaymentLinkInfoResponse) => {
			const uuid = paymentLinkInfoResponse.uuid;
			const status = paymentLinkInfoResponse.status;

			const paymentInfo = PaymentUtil.toPaymentInfoModel(paymentLinkInfoResponse);
			setPaymentInfo(paymentInfo);

			if (status === PAYMENT_LINK_STATUS.VALID) {
				if (!isLocalStorageAvailable()) {
					console.log('Local storage not available.');
				}
				// Save payment link info and start the timer
				paymentLocalStorageService.setPaymentInfo(paymentInfo);
				startPaymentLinkExpirationTimer(
					paymentLinkInfoResponse.expirationDate,
					uuid,
					countdownRef,
					setError
				);

				//get assets
				if (paymentLinkInfoResponse.consumerReference) {
					getAssetsOnPageLoad(paymentLinkInfoResponse);
				} else {
					skipLandingPageIfPossible(paymentLinkInfoResponse, handleRedirectToPayment);
				}
			} else {
				if (countdownRef.current) {
					countdownRef.current.unsubscribe();
				}

				PaymentUtil.updatePaymentInfoProperty(uuid, FIELD_TO_UPDATE.STATUS, status);
			}

			if (!paymentLinkInfoResponse.consumerReference || status !== PAYMENT_LINK_STATUS.VALID) {
				skipLandingPageIfPossible(paymentLinkInfoResponse, handleRedirectToPayment);
				dialogService.closeDialog(DIALOGS.LOADER);
			}
		},
		[setPaymentInfo, handleRedirectToPayment, getAssetsOnPageLoad, setError]
	);

	useEffect(() => {
		if (pathname) {
			const uuid = getPathParam(pathname, PATH_PARAM_NAME.UUID);

			const uuidValid = isUuidValid(uuid || '');

			if (!uuidValid) {
				navigate(`/session-not-found`);

				return;
			}
			if (uuid && isOnLandingPage(pathname)) {
				const callbackObj: CallbackObj<PaymentLinkInfoResponse> = {
					success: handleGetPaymentLinkInfoSuccess,
					error: handleGetPaymentLinkInfoError,
				};

				getPaymentLinkInfo(uuid, callbackObj);
			}
		}

		return () => {
			clearError();
		};
	}, [
		pathname,
		getPaymentLinkInfo,
		navigate,
		handleGetPaymentLinkInfoSuccess,
		handleGetPaymentLinkInfoError,
		clearError,
	]);

	return (
		<ThemeProvider theme={muiTheme}>
			<LoaderMemo />
			<PaymentLinkExpirationDialogMemo />
			<PageWrapper>
				{!isInIframe && <HeaderMemo />}
				<MainContent id="main">
					<HppRoutesMemo />
					<FooterMemo locale={locale} />
				</MainContent>
			</PageWrapper>
		</ThemeProvider>
	);
};

export default App;

const PageWrapper = styled.div`
	width: 100%;
	height: 100%;
`;

const MainContent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: ${isInIframe ? '100%' : `calc(100% - ${getVar('headerHeightMobile')})`};
	overflow-y: auto;
	background-color: ${getColor('background')};
	justify-content: flex-start;
	align-items: center;

	@media only screen and (min-width: 810px) {
		height: ${isInIframe ? '100%' : `calc(100% - ${getVar('headerHeight')})`};
	}
`;
