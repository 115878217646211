import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import './i18n';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import axios, { AxiosInstance } from 'axios';
import AppContextProvider from './store/AppContextProvider';
import FraudNetLibraryFacade from './core/components/layout/fraudnet-library/FraudNetLibraryFacade';

interface Config {
	baseUrl: string;
	appOrigin: string;
	gppBaseUrl: string;
	gppLibraryPath: string;
	adyenEnvironment: string;
	// Setting used to opt out of the workaround for zero amount payments with Adyen component without the need to do an entire deployment
	// Should be removed if and when Adyen fixes the issue on their end - 68326
	useAdyenZeroAmountWorkaround: boolean;
}

export const appSetup = (() => {
	console.log('Starting...');

	let axiosInstance: AxiosInstance;
	let environment: Config;

	function HostedPaymentPageApp() {
		useEffect(() => {
			console.log('Started.');
		});

		return (
			<BrowserRouter>
				<AppContextProvider>
					<FraudNetLibraryFacade>
						<App />
					</FraudNetLibraryFacade>
				</AppContextProvider>
			</BrowserRouter>
		);
	}

	const init = (): Promise<unknown> => {
		return fetch('/config.json', { cache: 'no-store' })
			.then((response) => response.json())
			.then((config: Config) => {
				if (config) {
					// Populate environment object
					environment = config;

					// Instantiate axios
					axiosInstance = axios.create({ baseURL: environment.baseUrl });

					const container = document.getElementById('root');

					const root = createRoot(container!);
					root.render(<HostedPaymentPageApp />);
				} else {
					console.log('Error retrieving configuration.');
				}
			});
	};

	return {
		start(): Promise<unknown> {
			return init();
		},
		getConfig(): Config {
			return environment;
		},
		getApplicationApi(): AxiosInstance {
			return axiosInstance;
		},
	};
})();

appSetup.start().catch((error) => console.log(error));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
