import { BreadcrumbsClassKey } from '@mui/material';
import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { getColor } from '../../colors/colors';
import { getFont } from '../../fonts/fonts';

export const CustomBreadcrumbs: Partial<OverridesStyleRules<BreadcrumbsClassKey>> | undefined = {
	root: {
		fontSize: '14px',
		lineHeight: '20px',

		'&.hppBreadcrumbs': {
			width: '500px',
			marginTop: '5px',
			marginBottom: '16px',

			'@media screen and (min-width: 745px)': {
				marginBottom: '16px',
			},
		},

		'& .MuiBreadcrumbs-li a': {
			fontFamily: getFont('mainFont'),
			fontSize: '14px',
			lineHeight: '20px',
			fontWeight: 'bold',
			letterSpacing: '0.4px',
			color: getColor('breadcrumbInactive'),
			backgroundColor: 'transparent',
		},

		'& .MuiBreadcrumbs-separator': {
			color: getColor('inputContrast'),
			marginLeft: '4px',
			marginRight: '4px',

			'& .MuiSvgIcon-root': {
				fontSize: '16px',
			},
		},

		'& .MuiBreadcrumbs-li p': {
			fontFamily: getFont('mainFont'),
			fontSize: '14px',
			lineHeight: '20px',
			fontWeight: 'bold',
			letterSpacing: '0.4px',
			color: getColor('inputContrast'),
			backgroundColor: 'transparent',
		},
	},
};

export const linkStyles = {
	position: 'relative',
	textDecoration: 'none',
	overflow: 'hidden',
	'&::before': {
		content: '""',
		position: 'absolute',
		bottom: -3,
		width: '100%',
		height: '1px',
		backgroundColor: `${getColor('cancelButtonBorder')}`,
		transform: 'scaleX(1)',
		transformOrigin: 'left',
	},
	'&::after': {
		content: '""',
		position: 'absolute',
		left: 0,
		bottom: -3,
		width: '100%',
		height: '2px',
		backgroundColor: `${getColor('cancelButtonBorder')}`,
		transform: 'scaleX(0)',
		transformOrigin: 'left',
		transition: 'transform 0.6s ease, height 0.6s ease',
	},
	'&:hover::after': {
		transform: 'scaleX(1)',
		height: '2px',
	},
};
