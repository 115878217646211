/* eslint-disable camelcase */
import PaymentCardIcon from '../assets/images/payment-card.svg';
import MasterCardIcon from '../assets/images/mastercard.svg';
import VisaElectronIcon from '../assets/images/visa-electron.svg';
import CreditCardIcon from '../assets/images/credit-card.svg';
import SepaIcon from '../assets/images/sepa.svg';
import PaypalIcon from '../assets/images/paypal.svg';
import PaymentSlipIcon from '../assets/images/payment-slip.svg';
import BancontactIcon from '../assets/images/bancontact.svg';
import KcpIcon from '../assets/images/kcp.svg';
import IdealIcon from '../assets/images/ideal.svg';
import NetbankingIcon from '../assets/images/netbanking.svg';
import UPIIcon from '../assets/images/upi.svg';
import AccountToPayIcon from '../assets/images/topay.svg';
import UPIQRCODEIcon from '../assets/images/upiQRcode.svg';
import PromptPayQRcodeIcon from '../assets/images/promptPayQRcode.svg';
import { PaymentInfoModel } from '../store/app-context';
import { paymentLocalStorageService } from '../core/services/local-storage/payment-link-ls.service';
import { t } from 'i18next';
import {
	PaymentLinkInfoResponse,
	TRANSACTION_TYPE_STRING,
	PaymentSettings,
	MANDATE_SCHEME,
	PAYPAL_TRANSACTION_TYPE,
	Assets,
} from '../data/types';
import { ASSET_TYPE } from './assets.util';
import { CUSTOM_CSS_GPP_FORM } from './theme.util';
import { INDIAN_METHOD_CUSTOM_CSS_GPP_FORM } from './indian-method-theme';
import { VIETNAM_INTEGRATION_ID_CUSTOM_CSS_GPP_FORM } from './vietnam-integrationId-theme';

export enum PAYMENT_LINK_STATUS {
	VALID = 'VALID',
	INVALIDATED = 'INVALIDATED',
	CONSUMED = 'CONSUMED',
	EXPIRED = 'EXPIRED',
	PROCESSING_PAYMENT = 'PROCESSING_PAYMENT',
}

export enum ADYEN_TRANSACTION_STATUS {
	AUTHORISED = 'AUTHORISED',
	RECEIVED = 'RECEIVED',
	REFUSED = 'REFUSED',
}

// ISO 4217 codes
export enum CURRENCY_CODES {
	EUR = 'EUR',
	KRW = 'KRW',
	SGD = 'SGD',
	INR = 'INR',
}

/* We use toLocaleString to format the currency; num.toLocaleString("ko-KR", {style:"currency", currency:"KRW"}); */
export const CURRENCY_SYMBOLS = {
	EUR: '€', // Euro
	KRW: '₩', // Korean won
	SGD: '$', // Singapore dollar
};

export enum PAYMENT_METHOD_PATH_SEGMENTS {
	CARD = 'card',
	REQUEST_2_PAY = 'direct-bank-transfer',
	IDEAL = 'ideal',
	BANCONTACT_MOBILE = 'bancontact-mobile',
	BANCONTACT_CARD = 'bancontact-card',
	KCP = 'kcp',
	SEPA_DD = 'sepa-dd',
	LANDING_PAGE = '',
	PAYPAL = 'paypal',
	PAYMENT_SLIP = 'payment-slip',
	NETBANKING = 'netbanking',
	UPI = 'upi',
	ACCOUNT_TO_PAY = 'account-to-pay',
	UPIQRCODE = 'upiqrcode',
	PROMPTPAYQR = 'promptpayqr',
}

export enum PAYMENT_METHODS {
	CARD = 'CARD',
	REQUEST_2_PAY = 'REQUEST_2_PAY',
	IDEAL = 'IDEAL',
	BANCONTACT_MOBILE = 'BANCONTACT_MOBILE',
	BANCONTACT_CARD = 'BANCONTACT_CARD',
	KCP = 'KCP',
	SEPA = 'SEPA_DD',
	PAYPAL = 'PAYPAL',
	PAYMENT_SLIP = 'PAYMENT_SLIP',
	NETBANKING = 'NETBANKING',
	UPI = 'UPI',
	ACCOUNT_TO_PAY = 'ACCOUNT_TO_PAY',
	UPIQRCODE = 'UPIQRCODE',
	PROMPTPAYQR = 'PROMPTPAYQR',
}

// LOCALE name is a combination of ISO language code and ISO country code (language_COUNTRY)
export enum LOCALES {
	bg_BG = 'bg_BG',
	cs_CZ = 'cs_CZ',
	da_DK = 'da_DK',
	de_DE = 'de_DE',
	de_AT = 'de_AT',
	de_CH = 'de_CH',
	de_LI = 'de_LI',
	de_LU = 'de_LU',
	el_GR = 'el_GR',
	en_CA = 'en_CA',
	en_CY = 'en_CY',
	en_GB = 'en_GB',
	en_IE = 'en_IE',
	en_JP = 'en_JP',
	en_KR = 'en_KR',
	en_MT = 'en_MT',
	en_SG = 'en_SG',
	en_TW = 'en_TW',
	en_US = 'en_US',
	en_ZA = 'en_ZA',
	es_ES = 'es_ES',
	et_EE = 'et_EE',
	fi_FI = 'fi_FI',
	fr_BE = 'fr_BE',
	fr_CA = 'fr_CA',
	fr_CH = 'fr_CH',
	fr_FR = 'fr_FR',
	fr_LI = 'fr_LI',
	fr_LU = 'fr_LU',
	hr_HR = 'hr_HR',
	hu_HU = 'hu_HU',
	it_CH = 'it_CH',
	it_IT = 'it_IT',
	it_LI = 'it_LI',
	ja_JP = 'ja_JP',
	ko_KR = 'ko_KR',
	lt_LT = 'lt_LT',
	lv_LV = 'lv_LV',
	nl_BE = 'nl_BE',
	nl_NL = 'nl_NL',
	nn_NO = 'nn_NO',
	no_NO = 'no_NO',
	pl_PL = 'pl_PL',
	pt_BR = 'pt_BR',
	pt_PT = 'pt_PT',
	ro_RO = 'ro_RO',
	ru_RU = 'ru_RU',
	sk_SK = 'sk_SK',
	sl_SI = 'sl_SI',
	sq_AL = 'sq_AL',
	sv_SE = 'sv_SE',
	sl_SL = 'sl_SL',
	th_TH = 'th_TH',
	tr_TR = 'tr_TR',
	vi_VN = 'vi_VN',
	zh_CN = 'zh_CN',
	zh_TW = 'zh_TW',
	en_MX = 'en_MX',
	es_MX = 'es_MX',
}

// Map all locale languages to the currently supported languages
export enum SUPPORTED_LANGUAGES_MAP {
	bg_BG = 'bg',
	cs_CZ = 'cs',
	da_DK = 'da',
	de_DE = 'de',
	de_AT = 'de',
	de_CH = 'de',
	de_LI = 'de',
	de_LU = 'de',
	el_GR = 'el',
	en_CA = 'en',
	en_CY = 'en',
	en_GB = 'en',
	en_IE = 'en',
	en_JP = 'en',
	en_KR = 'en',
	en_MT = 'en',
	en_SG = 'en',
	en_TW = 'en',
	en_US = 'en',
	en_ZA = 'en',
	es_ES = 'es',
	et_EE = 'et',
	fi_FI = 'fi',
	fr_BE = 'fr',
	fr_CA = 'fr',
	fr_CH = 'fr',
	fr_FR = 'fr',
	fr_LI = 'fr',
	fr_LU = 'fr',
	hr_HR = 'hr',
	hu_HU = 'hu',
	it_CH = 'it',
	it_IT = 'it',
	it_LI = 'it',
	ja_JP = 'ja',
	ko_KR = 'ko',
	lt_LT = 'lt',
	lv_LV = 'lv',
	nl_BE = 'nl',
	nl_NL = 'nl',
	nn_NO = 'en',
	no_NO = 'no',
	pl_PL = 'pl',
	pt_BR = 'pt',
	pt_PT = 'pt',
	ro_RO = 'ro',
	ru_RU = 'en',
	sk_SK = 'sk',
	sl_SI = 'en',
	sq_AL = 'en',
	sv_SE = 'sv',
	sl_SL = 'sl',
	th_TH = 'th',
	tr_TR = 'tr',
	vi_VN = 'vi',
	zh_CN = 'en',
	zh_TW = 'zh',
	en_MX = 'en',
	es_MX = 'es',
}

export enum LOCALES_DEUTSCHE_BANK {
	bg = 'bg',
	ca = 'ca',
	cs = 'cs',
	da = 'da',
	de = 'de',
	el = 'el',
	en = 'en',
	es = 'es',
	et = 'et',
	eu = 'eu',
	fi = 'fi',
	fr = 'fr',
	gl = 'gl',
	hr = 'hr',
	hu = 'hu',
	it = 'it',
	ja = 'ja',
	lt = 'lt',
	lv = 'lv',
	mt = 'mt',
	nl = 'nl',
	pl = 'pl',
	pt = 'pt',
	ro = 'ro',
	ru = 'ru',
	sk = 'sk',
	sl = 'sl',
	sv = 'sv',
	de_DE_sp = 'de_DE_sp',
	en_GB_sp = 'en_GB_sp',
}

// our locales mapped to Deutsche Bank locales
export const LOCALES_MAPPED = {
	BG_BG: { bg_BG: 'bg_BG', bg: 'bg' },
	CS_CZ: { cs_CZ: 'cs_CZ', cs: 'cs' },
	DA_DK: { da_DK: 'da_DK', da: 'da' },
	DE_DE: { de_DE: 'de_DE', de: 'de' },
	DE_AT: { de_AT: 'de_AT', de: 'de' },
	DE_CH: { de_CH: 'de_CH', de: 'de' },
	DE_LI: { de_LI: 'de_LI', de: 'de' },
	DE_LU: { de_LU: 'de_LU', de: 'de' },
	EL_GR: { el_GR: 'el_GR', el: 'el' },
	EN_CA: { en_CA: 'en_CA', en: 'en' },
	EN_CY: { en_CY: 'en_CY', en: 'en' },
	EN_GB: { en_GB: 'en_GB', en: 'en' },
	EN_IE: { en_IE: 'en_IE', en: 'en' },
	EN_JP: { en_JP: 'en_JP', en: 'en' },
	EN_KR: { en_KR: 'en_KR', en: 'en' },
	EN_MT: { en_MT: 'en_MT', en: 'en' },
	EN_SG: { en_SG: 'en_SG', en: 'en' },
	EN_TW: { en_TW: 'en_TW', en: 'en' },
	EN_US: { en_US: 'en_US', en: 'en' },
	EN_ZA: { en_ZA: 'en_ZA', en: 'en' },
	ES_ES: { es_ES: 'es_ES', es: 'es' },
	ET_EE: { et_EE: 'et_EE', et: 'et' },
	FI_FI: { fi_FI: 'fi_FI', fi: 'fi' },
	FR_BE: { fr_BE: 'fr_BE', fr: 'fr' },
	FR_CA: { fr_CA: 'fr_CA', fr: 'fr' },
	FR_CH: { fr_CH: 'fr_CH', fr: 'fr' },
	FR_FR: { fr_FR: 'fr_FR', fr: 'fr' },
	FR_LI: { fr_LI: 'fr_LI', fr: 'fr' },
	FR_LU: { fr_LU: 'fr_LU', fr: 'fr' },
	HR_HR: { hr_HR: 'hr_HR', hr: 'hr' },
	HU_HU: { hu_HU: 'hu_HU', hu: 'hu' },
	IT_CH: { it_CH: 'it_CH', it: 'it' },
	IT_IT: { it_IT: 'it_IT', it: 'it' },
	IT_LI: { it_LI: 'it_LI', it: 'it' },
	JA_JP: { ja_JP: 'ja_JP', ja: 'ja' },
	KO_KR: { ko_KR: 'ko_KR', ko: null },
	LT_LT: { lt_LT: 'lt_LT', lt: 'lt' },
	LV_LV: { lv_LV: 'lv_LV', lv: 'lv' },
	NL_BE: { nl_BE: 'nl_BE', nl: 'nl' },
	NL_NL: { nl_NL: 'nl_NL', nl: 'nl' },
	NN_NO: { nn_NO: 'nn_NO', nn: null },
	NO_NO: { no_NO: 'no_NO', no: null },
	PL_PL: { pl_PL: 'pl_PL', pl: 'pl' },
	PT_BR: { pt_BR: 'pt_BR', pt: 'pt' },
	PT_PT: { pt_PT: 'pt_PT', pt: 'pt' },
	RO_RO: { ro_RO: 'ro_RO', ro: 'ro' },
	RU_RU: { ru_RU: 'ru_RU', ru: 'ru' },
	SK_SK: { sk_SK: 'sk_SK', sk: 'sk' },
	SL_SI: { sl_SI: 'sl_SI', sl: 'sl' },
	SQ_AL: { sq_AL: 'sq_AL', sq: null },
	SV_SE: { sv_SE: 'sv_SE', sv: 'sv' },
	SL_SL: { sl_SL: 'sl_SL', sl: 'sl' },
	TH_TH: { th_TH: 'th_TH', th: null },
	TR_TR: { tr_TR: 'tr_TR', tr: null },
	VI_VN: { vi_VN: 'vi_VN', vi: null },
	ZH_CN: { zh_CN: 'zh_CN', zh: null },
	ZH_TW: { zh_TW: 'zh_TW', zh: null },
	EN_MX: { en_MX: 'en_MX', en: null },
	ES_MX: { es_MX: 'es_MX', en: null },
};

// LOCALES_MAPPED_ADYEN is a combination of ISO language code_ISO country code with supported Adyen locales
export enum LOCALES_MAPPED_ADYEN {
	bg_BG = 'bg_BG',
	cs_CZ = 'cs_CZ',
	da_DK = 'da_DK',
	de_DE = 'de_DE',
	de_AT = 'de_DE',
	de_CH = 'de_DE',
	de_LI = 'de_DE',
	de_LU = 'de_DE',
	el_GR = 'el_GR',
	en_CA = 'en_US',
	en_CY = 'en_US',
	en_GB = 'en_US',
	en_IE = 'en_US',
	en_JP = 'en_US',
	en_KR = 'en_US',
	en_MT = 'en_US',
	en_SG = 'en_US',
	en_TW = 'en_US',
	en_US = 'en_US',
	en_ZA = 'en_US',
	es_ES = 'es_ES',
	et_EE = 'et_EE',
	fi_FI = 'fi_FI',
	fr_BE = 'fr_FR',
	fr_CA = 'fr_FR',
	fr_CH = 'fr_FR',
	fr_FR = 'fr_FR',
	fr_LI = 'fr_FR',
	fr_LU = 'fr_FR',
	hr_HR = 'hr_HR',
	hu_HU = 'hu_HU',
	it_CH = 'it_IT',
	it_IT = 'it_IT',
	it_LI = 'it_IT',
	ja_JP = 'ja_JP',
	ko_KR = 'ko_KR',
	lt_LT = 'lt_LT',
	lv_LV = 'lv_LV',
	nl_BE = 'nl_NL',
	nl_NL = 'nl_NL',
	nn_NO = 'no_NO',
	no_NO = 'no_NO',
	pl_PL = 'pl_PL',
	pt_BR = 'pt_BR',
	pt_PT = 'pt_PT',
	ro_RO = 'ro_RO',
	ru_RU = 'ru_RU',
	sk_SK = 'sk_SK',
	sl_SI = 'sl_SI',
	sq_AL = 'en_US',
	sv_SE = 'sv_SE',
	sl_SL = 'sl_SL',
	th_TH = 'th_TH',
	tr_TR = 'tr_TR',
	vi_VN = 'vi_VN',
	zh_CN = 'zh_CN',
	zh_TW = 'zh_TW',
	en_MX = 'en_US',
	es_MX = 'es_ES',
}

export enum LANGS {
	bg = 'bg',
	cs = 'cs',
	da = 'da',
	de = 'de',
	el = 'el',
	en = 'en',
	es = 'es',
	et = 'et',
	fi = 'fi',
	fr = 'fr',
	hr = 'hr',
	hu = 'hu',
	it = 'it',
	ja = 'ja',
	ko = 'ko',
	lt = 'lt',
	lv = 'lv',
	nl = 'nl',
	nn = 'nn',
	no = 'no',
	pl = 'pl',
	pt = 'pt',
	ro = 'ro',
	ru = 'ru',
	sk = 'sk',
	sl = 'sl',
	sq = 'sq',
	sv = 'sv',
	tr = 'tr',
	zh = 'zh',
}

export enum COUNTRIES {
	BG = 'BG',
	CZ = 'CZ',
	DK = 'DK',
	DE = 'DE',
	AT = 'AT',
	CH = 'CH',
	LI = 'LI',
	LU = 'LU',
	GR = 'GR',
	CA = 'CA',
	CY = 'CY',
	GB = 'GB',
	IE = 'IE',
	JP = 'JP',
	KR = 'KR',
	MT = 'MT',
	TW = 'TW',
	US = 'US',
	ZA = 'ZA',
	ES = 'ES',
	EE = 'EE',
	FI = 'FI',
	BE = 'BE',
	FR = 'FR',
	HR = 'HR',
	HU = 'HU',
	IT = 'IT',
	LT = 'LT',
	LV = 'LV',
	NL = 'NL',
	NO = 'NO',
	PL = 'PL',
	PT = 'PT',
	RO = 'RO',
	RU = 'RU',
	SG = 'SG',
	SK = 'SK',
	SI = 'SI',
	AL = 'AL',
	SE = 'SE',
	TR = 'TR',
	CN = 'CN',
	MX = 'MX',
}

export enum PROCESSING_OPTIONS_STRING {
	INSTANT = 'INSTANT',
	INSTANT_IF_POSSIBLE = 'INSTANT_IF_POSSIBLE',
}

export type PaymentMethodConfig = {
	name: string;
	icon: string;
	supportedCards?: string[];
};

export const PAYMENT_METHOD_DATA: Record<string, PaymentMethodConfig> = {
	CARD: {
		name: 'landingPage.card',
		icon: PaymentCardIcon,
		supportedCards: [MasterCardIcon, VisaElectronIcon],
	},
	REQUEST_2_PAY: {
		name: 'landingPage.directBankTransfer',
		icon: SepaIcon,
	},
	BANCONTACT_CARD: {
		name: 'landingPage.bancontactCard',
		icon: BancontactIcon,
	},
	BANCONTACT_MOBILE: {
		name: 'landingPage.bancontactMobile',
		icon: BancontactIcon,
	},
	KCP: {
		name: 'landingPage.kcp',
		icon: KcpIcon,
	},
	IDEAL: {
		name: 'landingPage.ideal',
		icon: IdealIcon,
	},
	SEPA_DD: {
		name: 'landingPage.sepadd',
		icon: SepaIcon,
	},
	SEPA_B2B: {
		name: 'landingPage.sepaddb2b',
		icon: SepaIcon,
	},
	PAYPAL: {
		name: 'landingPage.paypal',
		icon: PaypalIcon,
	},
	PAYMENT_SLIP: {
		name: 'landingPage.paymentSlip',
		icon: PaymentSlipIcon,
	},
	NETBANKING: {
		name: 'landingPage.netbanking',
		icon: NetbankingIcon,
	},
	UPI: {
		name: 'landingPage.upi',
		icon: UPIIcon,
	},
	ACCOUNT_TO_PAY: {
		name: 'landingPage.accountToPay',
		icon: AccountToPayIcon,
	},
	UPIQRCODE: {
		name: 'landingPage.upiQRcode',
		icon: UPIQRCODEIcon,
	},
	PROMPTPAYQR: {
		name: 'landingPage.promptpayQRcode',
		icon: PromptPayQRcodeIcon,
	},
};

export enum ICON_TYPE {
	ERROR = 'ERROR',
	INFO = 'INFO',
}

export enum POST_MESSAGE_TYPE {
	SUCCESS = 'SUCCESS',
	FAILURE = 'FAILURE',
	ERROR = 'ERROR',
}

export type PostMessage = {
	type: POST_MESSAGE_TYPE;
	payload: any;
};

export const PAYMENT_LINK_EXPIRATION_INTERVAL_SIZE = 1000; // Milliseconds
export const PAYMENT_LINK_EXPIRATION_SHOW_DIALOG = 180; // Seconds

export enum CARD_TYPE {
	VISA = 'VISA',
	MASTERCARD = 'MASTERCARD',
	UNKNOWN = 'UNKNOWN',
}

export type CardTypeConfig = {
	name: string;
	icon: string;
};

export const CARD_TYPE_RECORD: Record<CARD_TYPE, CardTypeConfig> = {
	VISA: {
		name: 'VISA',
		icon: VisaElectronIcon,
	},
	MASTERCARD: {
		name: 'Mastercard',
		icon: MasterCardIcon,
	},
	UNKNOWN: {
		name: 'unknown',
		icon: CreditCardIcon,
	},
};

export enum APP_ORIGIN {
	DEV = 'https://mpay-hpp-dev.p3ds.net',
	TST = 'https://mpay-hpp-tst.p3ds.net',
}

export enum FIELD_TO_UPDATE {
	STATUS = 'status',
	SELECTED_PAYMENT_METHOD = 'selectedPaymentMethod',
}

export class PaymentUtil {
	/**
	 * Gets the formatted payment amount by adding decimals and currency symbol to the raw amount
	 * @param locale - The code showing language and country (en_GB)
	 * @param amount - The amount of the purchase i.e. 1000
	 * @param currency - The currency for this locale
	 * @returns Currency symbol + formatted amount  (€10,00)
	 */
	static getFormattedAmount = (
		locale = '',
		amount = 0,
		currency: string = CURRENCY_CODES.EUR
	): string => {
		// We get ISO format with underscore from API, and toLocaleString() expects dash
		let formattedLocale = locale.replace(/_/g, '-');

		// KRW doesn't have decimals; EUR's smallest unit is cent
		let newAmount = amount;
		if (currency !== CURRENCY_CODES.KRW) {
			newAmount = amount / 100;
		}

		// If currency = SGD and locale is anything other than en_SG,
		// toLocaleString() doesn't show dollar symbol, but "SGD"
		if (currency === CURRENCY_CODES.SGD) {
			formattedLocale = LOCALES.en_SG.replace(/_/g, '-');
		}
		const options = {
			style: 'currency',
			currency,
			currencyDisplay: 'code',
		};

		return newAmount.toLocaleString(formattedLocale, options);
	};

	/**
	 * Retrieves all payment link info
	 * @param paymentLinkInfoResponse - The data about the payment link
	 * @returns All required data from paymentLinkInfoResponse
	 */
	static toPaymentInfoModel = (
		paymentLinkInfoResponse: PaymentLinkInfoResponse
	): PaymentInfoModel => {
		const locale = paymentLinkInfoResponse.locale;
		const amount = paymentLinkInfoResponse.amount;
		const currency: CURRENCY_CODES = paymentLinkInfoResponse.currency;

		return {
			merchantId: paymentLinkInfoResponse.merchantId,
			uuid: paymentLinkInfoResponse.uuid,
			amount,
			formattedAmount: PaymentUtil.getFormattedAmount(locale, amount, currency),
			currency,
			locale,
			returnUrl: paymentLinkInfoResponse.returnUrl,
			termsAndConditionsUrl: paymentLinkInfoResponse.termsAndConditionsUrl || '',
			paymentMethods: paymentLinkInfoResponse.paymentMethods as PAYMENT_METHODS[],
			merchantName: paymentLinkInfoResponse.merchantName || '--',
			merchantReference: paymentLinkInfoResponse.merchantReference,
			skipLandingPage: paymentLinkInfoResponse.skipLandingPage,
			description: paymentLinkInfoResponse.description || '',
			status: paymentLinkInfoResponse.status,
			dateCreated: paymentLinkInfoResponse.dateCreated,
			paymentSettings: paymentLinkInfoResponse.paymentSettings || null,
			returnUrlTimer: paymentLinkInfoResponse.returnUrlTimer || 0,
			expirationDate: paymentLinkInfoResponse.expirationDate,
			selectedPaymentMethod: null,
			consumerReference: paymentLinkInfoResponse.consumerReference,
			isSubscription: paymentLinkInfoResponse.isSubscription,
			backToShopUrl: paymentLinkInfoResponse.backToShopUrl,
			showAssetsIfSkipLandingPageIsTrue: paymentLinkInfoResponse.showAssetsIfSkipLandingPageIsTrue,
			paypalMerchantId: paymentLinkInfoResponse.paypalMerchantId,
			requireConsent: paymentLinkInfoResponse.requireConsent,
		};
	};

	/**
	 * Updates payment information about the payment link configuration
	 * @param uuid - The uuid is the unique payment link identifier.
	 * @param key - The key value pair for update payment info property
	 * @param newValue - The new value to update the payment info
	 */
	static updatePaymentInfoProperty = (uuid: string, key: any, newValue: any) => {
		const payments = paymentLocalStorageService.getPayments();
		const newPayments = payments.map((payment: PaymentInfoModel) => {
			if (payment.uuid === uuid) {
				return { ...payment, [key]: newValue };
			} else {
				return payment;
			}
		});
		paymentLocalStorageService.setPayments(newPayments);
	};

	/**
	 * Retrieves the payment method and segment pair
	 * @param paymentMethod - Оne of the possible payment methods from the PAYMENT_METHODS enum
	 * @returns [paymentSegment, selectedPaymentMethod] - paymentSegment is the same as the selected payment method
	 */

	static getPaymentMethodAndSegmentPair = (
		paymentMethod: string
	): [PAYMENT_METHOD_PATH_SEGMENTS, PAYMENT_METHOD_PATH_SEGMENTS] => {
		const paymentSegment =
			paymentMethod !== PAYMENT_METHODS.REQUEST_2_PAY
				? (paymentMethod.replaceAll('_', '-').toLowerCase() as PAYMENT_METHOD_PATH_SEGMENTS)
				: PAYMENT_METHOD_PATH_SEGMENTS.REQUEST_2_PAY;

		const selectedPaymentMethod = paymentSegment;

		//TODO Return only 1 of the values, since they are the same
		return [paymentSegment, selectedPaymentMethod];
	};
}

/**
 * Changes the button text for Card payment method depending on transaction type
 * @param paymentInfo -  The payment info object to update
 * @returns The button text for Card payment method
 */
export const getCardPayNowOrAuthorizeBtnText = (paymentInfo: PaymentInfoModel | null) => {
	let btnGoOnTrans = t('cardPayment.payNow'); // preauthAndCapture and debit

	if (paymentInfo?.paymentSettings?.card?.transactionType === TRANSACTION_TYPE_STRING.preauth) {
		btnGoOnTrans = t('cardPayment.authorizePayment');
	}

	return btnGoOnTrans;
};

/**
 * Changes the button text for Sepa payment method depending on amount
 * @param amount - The amount of the purchase
 * @returns The button text for Sepa payment method
 */
export const getSepaPayNowOrAuthorizeBtnText = (amount: number) => {
	let sepaBtnGoOnTrans = t('sepa.payNow'); // when amount !== 0

	if (amount === 0) {
		sepaBtnGoOnTrans = t('sepa.authorizePayment');
	}

	return sepaBtnGoOnTrans;
};

/**P
 * Changes the button text for Sepa asset depending on amount
 * @param amount - The amount of the purchase
 * @returns The button text for Sepa asset
 */
export const getSepaPayNowOrSelectBtnText = (amount: number) => {
	let sepaBtnGoOnTrans = t('sepa.payNow'); // when amount !== 0

	if (amount === 0) {
		sepaBtnGoOnTrans = t('sepa.selectMandate');
	}

	return sepaBtnGoOnTrans;
};

/**
 * Changes the button text for PayPal payment method depending on transaction type
 * @param paymentInfo -  The payment info object to update
 * @returns The button text for PayPal payment method
 */
export const getPayPalPayNowOrAuthorizeBtnText = (paymentInfo: PaymentInfoModel | null) => {
	let btnGoOnTrans = t('paypal.payNow'); // preauthAndCapture

	if (paymentInfo?.paymentSettings?.payPal?.transactionType === PAYPAL_TRANSACTION_TYPE.preauth) {
		if (paymentInfo?.amount === 0) {
			btnGoOnTrans = t('paypal.selectBillingAgreement');
		} else {
			btnGoOnTrans = t('paypal.authorizePayment');
		}
	}

	return btnGoOnTrans;
};

/**
 * Sets the selected payment method for a given payment info
 * @param paymentMethodPathSegment - paymentSegment is the same as the selected payment method
 * @param paymentInfo - The payment info object to update
 * @param setPaymentInfo - A function that updates payment info
 * @returns void
 */
export const setSelectedPaymentMethod = (
	paymentMethodPathSegment: PAYMENT_METHOD_PATH_SEGMENTS,
	paymentInfo: PaymentInfoModel,
	setPaymentInfo: (paymentInfo: PaymentInfoModel) => void
) => {
	if (!paymentInfo) return;

	//update selectedPaymentMethod in context
	setPaymentInfo({
		...paymentInfo,
		selectedPaymentMethod: paymentMethodPathSegment,
	});

	//update selectedPaymentMethod in local storage
	PaymentUtil.updatePaymentInfoProperty(
		paymentInfo.uuid,
		FIELD_TO_UPDATE.SELECTED_PAYMENT_METHOD,
		paymentMethodPathSegment
	);
};

/**
 * Check if mandate scheme for Sepa payment method is B2B
 * @param paymentSettings - Settings for the selected payment method from the PaymentSettings interface
 * @returns "true" if mandate scheme is B2B and "false" otherwise
 */
export const isB2B = (paymentSettings: PaymentSettings | null): boolean => {
	if (paymentSettings === null) {
		return false;
	}

	const mandateScheme = paymentSettings?.sepa?.mandateScheme;

	return mandateScheme === MANDATE_SCHEME.SEPADDB2B || mandateScheme === MANDATE_SCHEME.SEPADD_B2B;
};

/**
 *
 * @returns 'true' if has PayPal Asset or  CIT transaction
 */
export const shouldLoadFraudNetLibrary = (
	assets: Assets | null,
	selectedPaymentMethod: string | null,
	isSubscription: boolean,
	amount: number
) => {
	const hasPayPalAssetAndNoZeroAmount =
		assets && assets[ASSET_TYPE.PAYPAL].length > 0 && amount > 0;
	const isCITTransaction =
		selectedPaymentMethod === PAYMENT_METHOD_PATH_SEGMENTS.PAYPAL && isSubscription && amount > 0;

	return hasPayPalAssetAndNoZeroAmount || isCITTransaction;
};

export enum INTEGRATION_ID {
	BIN_IIN_ID = 'f3b05210-a841-4fef-9290-fc7d20928f22',
	ONLY_DEBIT_CARD_ID = '069c9baa-9a60-472e-afdc-0620c831fc78',
	COMPLIANCE_SERVICE_ID = '175bc7c8-b6ea-4dd0-8871-3ec36eb88175',
	PAYU_INDIA_ID = '25028fd4-0b8b-4ef6-8b89-3f1be60d60b2',
	KCP_DIRECT_ID = '3f91ce88-06c1-49fe-9eb6-745f915fec83',
	NON_CIAM_ID = 'f2408504-7731-11ee-b962-0242ac120002',
	ACI_VIETNAM_ID = 'aa1bef0a-7706-11ee-b962-0242ac120002',
}

export const cssByIntegrationMap = new Map([
	[INTEGRATION_ID.BIN_IIN_ID, CUSTOM_CSS_GPP_FORM],
	[INTEGRATION_ID.ONLY_DEBIT_CARD_ID, CUSTOM_CSS_GPP_FORM],
	[INTEGRATION_ID.COMPLIANCE_SERVICE_ID, CUSTOM_CSS_GPP_FORM],
	[INTEGRATION_ID.PAYU_INDIA_ID, INDIAN_METHOD_CUSTOM_CSS_GPP_FORM],
	[INTEGRATION_ID.KCP_DIRECT_ID, CUSTOM_CSS_GPP_FORM],
	[INTEGRATION_ID.NON_CIAM_ID, CUSTOM_CSS_GPP_FORM],
	[INTEGRATION_ID.ACI_VIETNAM_ID, VIETNAM_INTEGRATION_ID_CUSTOM_CSS_GPP_FORM],
]);
