export enum PROCESSING_OPTIONS {
	DEPOSIT = 'deposit',
	BALANCE = 'balance',
}

export type GetPaymentSlipResponseDto = {
	uuid: string;
	amount: number;
	currency: string;
	shopperBankStatementReference: string;
	merchantReference: string;
	merchantId: string;
	paymentMethod: string;
	dateCreated: string;
	paymentSlip: PaymentSlip;
	identifiers: Array<string>;
	paymentSlipUrl: string;
};

export interface PaymentSlip {
	customerName: string;
	referenceNumber: string;
	processingOption: PROCESSING_OPTIONS;
}
